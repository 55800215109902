import React, { Suspense, lazy } from "react";
import "./App.css";
import { SMProvider } from "./context/smProvider";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import MainPage from "./MainPage";
import ServerSelect from "./containers/login/ServerSelect";
import TestPage from "containers/testReport/TestPage";
import "./global";
import BigProgress from "components/BigProgress";
import ScreenMsg from "components/ScreenMsg";
// import MapDialog from "containers/map/MapDialog";
const InitialTestPage = lazy(() =>
  import("./containers/login/InitialTestPage")
);
const LoginPage = lazy(() => import("./containers/login/LoginPage"));
const HomePage = lazy(() => import("./containers/home/HomePage"));
const AddMapDialog = lazy(() => import("containers/home/AddMapDialog"));
const UserDialog = lazy(() => import("containers/home/UserDialog"));
const MapDialog = lazy(() => import("containers/map/MapDialog"));
const AllDevicesListDialog = lazy(() =>
  import("containers/home/AllDevicesListDialog")
);
const DeviceListDialog = lazy(() =>
  import("./containers/map/DeviceListDialog")
);
const AddDeviceToMapDialog = lazy(() =>
  import("./containers/map/AddDeviceToMapDialog")
);
const MapSettingDialog = lazy(() =>
  import("./containers/map/MapSettingDialog")
);
const UploadImageDialog = lazy(() =>
  import("./containers/map/UploadImageDialog")
);
const UploadImageFBDialog = lazy(() =>
  import("./containers/map/UploadImageFBDialog")
);
const LockLevelSettingModal = lazy(() =>
  import("./containers/map/LockLevelSettingModal")
);
const GroupReportDialog = lazy(() =>
  import("containers/testReport/GroupReportDialog")
);
const TestScheduleDialog = lazy(() =>
  import("containers/testReport/TestScheduleDialog")
);
const BatchEditScheduleDialog = lazy(() =>
  import("containers/testReport/testSchedule/BatchEditScheduleDialog")
);
const LightEditScheduleDialog = lazy(() =>
  import("containers/testReport/testSchedule/LightEditScheduleDialog")
);
const EditSingleTimeScheduleDialog = lazy(() =>
  import("containers/testReport/testSchedule/EditSingleTimeScheduleDialog")
);

const EditReportEmailFBDialog = lazy(() =>
  import("containers/testReport/EditReportEmailFBDialog")
);
const EditReportEmailDialog = lazy(() =>
  import("containers/testReport/EditReportEmailDialog")
);
const LightDetailDialog = lazy(() =>
  import("./containers/serial/LightDetailDialog")
);
const SensorDetailDialog = lazy(() =>
  import("./containers/sensor/SensorDetailDialog")
);
const GatewayDetailDialog = lazy(() =>
  import("./containers/gateway/GatewayDetailDialog")
);
const ZoneControlDetailDialog = lazy(() =>
  import("./containers/zoneControl/ZoneControlDetailDialog")
);
const DeviceChangeGatewayDialog = lazy(() =>
  import("./containers/gateway/DeviceChangeGatewayDialog")
);
const LightReportListDialog = lazy(() =>
  import("containers/testReport/LightReportListDialog")
);
const TestViewDialog = lazy(() =>
  import("containers/testReport/TestViewDialog")
);
const AllLightReportDialog = lazy(() =>
  import("containers/home/AllLightReportDialog")
);
const SingleReportDialog = lazy(() =>
  import("containers/testReport/SingleReportDialog")
);

export default function App() {
  const theme = createTheme({
    palette: { type: "dark" },
  });
  function Loading() {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#000",
        }}
      >
        ...loading
      </div>
    );
  }
  return (
    <SMProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/nte">
              <ServerSelect />
            </Route>
            <Route path="/TestPage">
              <TestPage />
            </Route>
            <Route path="/InitialTestPage">
              <Suspense fallback={<Loading />}>
                <InitialTestPage />
              </Suspense>
            </Route>
            <Route path="/LoginPage">
              <Suspense fallback={<Loading />}>
                <LoginPage />
              </Suspense>
            </Route>
            <Route path="/HomePage">
              <Suspense fallback={<Loading />}>
                <HomePage />
              </Suspense>
            </Route>
            <Route path="/AddMapDialog">
              <Suspense fallback={<Loading />}>
                <AddMapDialog />
              </Suspense>
            </Route>
            <Route path="/UserDialog">
              <Suspense fallback={<Loading />}>
                <UserDialog />
              </Suspense>
            </Route>
            <Route path="/AllDevicesListDialog">
              <Suspense fallback={<Loading />}>
                <AllDevicesListDialog />
              </Suspense>
            </Route>
            <Route path="/MapDialog">
              <Suspense fallback={<Loading />}>
                <MapDialog />
              </Suspense>
            </Route>
            <Route path="/DeviceListDialog">
              <Suspense fallback={<Loading />}>
                <DeviceListDialog />
              </Suspense>
            </Route>
            <Route path="/AddDeviceToMapDialog">
              <Suspense fallback={<Loading />}>
                <AddDeviceToMapDialog />
              </Suspense>
            </Route>
            <Route path="/MapSettingDialog">
              <Suspense fallback={<Loading />}>
                <MapSettingDialog />
              </Suspense>
            </Route>
            <Route path="/UploadImageDialog">
              <Suspense fallback={<Loading />}>
                <UploadImageDialog />
              </Suspense>
            </Route>
            <Route path="/UploadImageFBDialog">
              <Suspense fallback={<Loading />}>
                <UploadImageFBDialog />
              </Suspense>
            </Route>
            <Route path="/LockLevelSettingModal">
              <Suspense fallback={<Loading />}>
                <LockLevelSettingModal />
              </Suspense>
            </Route>
            <Route path="/GroupReportDialog">
              <Suspense fallback={<Loading />}>
                <GroupReportDialog />
              </Suspense>
            </Route>
            <Route path="/TestScheduleDialog">
              <Suspense fallback={<Loading />}>
                <TestScheduleDialog />
              </Suspense>
            </Route>
            <Route path="/BatchEditScheduleDialog">
              <Suspense fallback={<Loading />}>
                <BatchEditScheduleDialog />
              </Suspense>
            </Route>
            <Route path="/LightEditScheduleDialog">
              <Suspense fallback={<Loading />}>
                <LightEditScheduleDialog />
              </Suspense>
            </Route>
            <Route path="/EditSingleTimeScheduleDialog">
              <Suspense fallback={<Loading />}>
                <EditSingleTimeScheduleDialog />
              </Suspense>
            </Route>
            <Route path="/EditReportEmailFBDialog">
              <Suspense fallback={<Loading />}>
                <EditReportEmailFBDialog />
              </Suspense>
            </Route>
            <Route path="/EditReportEmailDialog">
              <Suspense fallback={<Loading />}>
                <EditReportEmailDialog />
              </Suspense>
            </Route>
            <Route path="/LightDetailDialog">
              <Suspense fallback={<Loading />}>
                <LightDetailDialog />
              </Suspense>
            </Route>
            <Route path="/SensorDetailDialog">
              <Suspense fallback={<Loading />}>
                <SensorDetailDialog />
              </Suspense>
            </Route>
            <Route path="/GatewayDetailDialog">
              <Suspense fallback={<Loading />}>
                <GatewayDetailDialog />
              </Suspense>
            </Route>
            <Route path="/ZoneControlDetailDialog">
              <Suspense fallback={<Loading />}>
                <ZoneControlDetailDialog />
              </Suspense>
            </Route>
            <Route path="/DeviceChangeGatewayDialog">
              <Suspense fallback={<Loading />}>
                <DeviceChangeGatewayDialog />
              </Suspense>
            </Route>
            <Route path="/LightReportListDialog">
              <Suspense fallback={<Loading />}>
                <LightReportListDialog />
              </Suspense>
            </Route>
            <Route path="/TestViewDialog">
              <Suspense fallback={<Loading />}>
                <TestViewDialog />
              </Suspense>
            </Route>
            <Route path="/AllLightReportDialog">
              <Suspense fallback={<Loading />}>
                <AllLightReportDialog />
              </Suspense>
            </Route>
            <Route path="/SingleReportDialog">
              <Suspense fallback={<Loading />}>
                <SingleReportDialog />
              </Suspense>
            </Route>
            {/* <Route path="/:serverCode">
              <MainPage />
            </Route> */}
            {/* <Route path="/">
              <MainPage />
            </Route> */}
            <Route path="/:serverCode">
              <Suspense fallback={<Loading />}>
                <InitialTestPage />
              </Suspense>
            </Route>
            <Route path="/">
              <Suspense fallback={<Loading />}>
                <InitialTestPage />
              </Suspense>
            </Route>
          </Switch>
        </Router>
        <BigProgress />
        <ScreenMsg />
      </ThemeProvider>
    </SMProvider>
  );
}
