import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";

const IconButtonClose = ({ onBtnClick }) => {
  const handleAction = () => {
    onBtnClick();
  };

  return (
    <IconButton onClick={() => handleAction()}>
      <Close />
    </IconButton>
  );
};

export default IconButtonClose;
