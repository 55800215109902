import {
  updateDocFS,
  getDocsWhereFS,
  batchDeleteDocsFS,
  batchSetDocArrayFS,
  removeFieldValueFS,
  getDocFS,
  unionArrayItemFS,
  removeArrayItemFS,
  addDocFS,
  getDocsByFieldArrayAndWhere,
  deleteDocFS,
} from "./firebaseActions";
import {
  updateFieldDB,
  getDocsBetweenRange,
  deleteManyDocsByQuery,
  insertManyDocsDB,
  updateRemoveFieldDB,
  getOneDocByQuery,
  fieldArrayPushDB,
  fieldArrayRemoveDB,
  insertDocDB,
  getDocsByArrayAndMatchFieldLS,
  deleteDocByQuery,
} from "./mongoApiActions";

// if (serverType === "firebase") {
//   ////
// } else if (serverType === "localserver") {
//   ////
// }

//////////SET////////////////////////SET////////////////////////SET////////////////////////SET//////////////
export async function updateDocLF(
  serverType,
  fsDocPath,
  mgCollection,
  query,
  updateObj
) {
  if (serverType === "firebase") {
    await updateDocFS(fsDocPath, updateObj);
  } else if (serverType === "localserver") {
    await updateFieldDB(mgCollection, query, updateObj);
  }
}

export async function insertOneDocLF(
  serverType,
  fsDocPath,
  mgCollection,
  insertObj
) {
  if (serverType === "firebase") {
    await addDocFS(fsDocPath, insertObj);
  } else if (serverType === "localserver") {
    await insertDocDB(mgCollection, insertObj);
  }
}
export async function deleteOneDocLF(
  serverType,
  fsDocPath,
  mgCollection,
  query
) {
  if (serverType === "firebase") {
    await deleteDocFS(fsDocPath);
  } else if (serverType === "localserver") {
    await deleteDocByQuery(mgCollection, query);
  }
}
export async function deleteManyDocsLF(
  serverType,
  fsCollectionPath,
  mgCollection,
  whereField,
  compareVal
) {
  if (serverType === "firebase") {
    await batchDeleteDocsFS(fsCollectionPath, whereField, "==", compareVal);
  } else if (serverType === "localserver") {
    await deleteManyDocsByQuery(mgCollection, { [whereField]: compareVal });
  }
}

export async function insertManyDocsLF(
  serverType,
  fsCollectionPath,
  mgCollection,
  insertDocArray,
  fsDocIDField
) {
  if (serverType === "firebase") {
    await batchSetDocArrayFS(fsCollectionPath, insertDocArray, fsDocIDField);
  } else if (serverType === "localserver") {
    await insertManyDocsDB(mgCollection, insertDocArray);
  }
}
export async function removeFieldValueLF(
  serverType,
  pathWithDocName,
  mgCollection,
  query,
  field,
  fieldValue
) {
  if (serverType === "firebase") {
    await removeFieldValueFS(pathWithDocName, field, fieldValue);
  } else if (serverType === "localserver") {
    const removeObj = { [`${field}.${fieldValue}`]: "" };
    await updateRemoveFieldDB(mgCollection, query, removeObj);
  }
}
export async function unionArrayItemLF(
  serverType,
  pathWithDocName,
  mgCollection,
  query,
  field,
  unionItem
) {
  if (serverType === "firebase") {
    await unionArrayItemFS(pathWithDocName, field, unionItem);
  } else if (serverType === "localserver") {
    await fieldArrayPushDB(mgCollection, query, field, unionItem);
  }
}
export async function removeArrayItemLF(
  serverType,
  pathWithDocName,
  mgCollection,
  query,
  field,
  removeItem
) {
  if (serverType === "firebase") {
    await removeArrayItemFS(pathWithDocName, field, removeItem);
  } else if (serverType === "localserver") {
    await fieldArrayRemoveDB(mgCollection, query, field, removeItem);
  }
}
//////////GET////////////////////GET////////////////////GET////////////////////GET///////////
export async function getOneDocLF(serverType, fsDocPath, mgCollection, query) {
  let doc;
  if (serverType === "firebase") {
    doc = await getDocFS(fsDocPath);
  } else if (serverType === "localserver") {
    doc = await getOneDocByQuery(mgCollection, query);
  }
  return doc;
}

export async function getDocsWhereLF(
  serverType,
  fsCollectionPath,
  mgCollection,
  whereField,
  compareVal,
  orderByField,
  order,
  rangeField,
  upperLimit,
  lowerLimit,
  fetchQty
) {
  let resultArray;
  if (serverType === "firebase") {
    resultArray = await getDocsWhereFS(
      fsCollectionPath,
      whereField,
      "==",
      compareVal,
      "array",
      orderByField,
      order,
      fetchQty,
      rangeField,
      upperLimit,
      lowerLimit
    );
  } else if (serverType === "localserver") {
    resultArray = await getDocsBetweenRange(
      mgCollection,
      { [whereField]: compareVal },
      rangeField,
      upperLimit,
      lowerLimit,
      orderByField,
      order
    );
  }
  return resultArray;
}

export async function getDocsByFieldArrayAndWhereLF(
  serverType,
  fsCollectionPath,
  mgCollection,
  byFieldName,
  searchArray,
  whereFieldArray,
  whereFieldCompareVal
) {
  let resultArray = [];
  if (serverType === "firebase") {
    resultArray = await getDocsByFieldArrayAndWhere(
      fsCollectionPath,
      byFieldName,
      searchArray,
      whereFieldArray,
      whereFieldCompareVal
    );
  } else if (serverType === "localserver") {
    resultArray = await getDocsByArrayAndMatchFieldLS(
      mgCollection,
      byFieldName,
      searchArray,
      whereFieldArray,
      whereFieldCompareVal
    );
  }
  return resultArray;
}
