import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
// let storageRef, databaseRef,authRef,firestoreRef;
var firebaseConfig = {
  apiKey: "AIzaSyAnCVCQ8WRkdQjwMMV-jGvliCiKq29EbiE",
  authDomain: "remomov2.firebaseapp.com",
  databaseURL: "https://remomov2.firebaseio.com",
  projectId: "remomov2",
  storageBucket: "remomov2.appspot.com",
  messagingSenderId: "421814643462",
};

firebase.initializeApp(firebaseConfig);

console.log("firebase skymon initialize.......");
let storageRef = firebase.storage().ref();
let databaseRef = firebase.database().ref();
let authRef = firebase.auth();
let firestoreRef = firebase.firestore();

export { storageRef, databaseRef, authRef, firestoreRef };
