import { Divider } from "@material-ui/core";

export default function Divider10({ space, variant, light }) {
  return (
    <Divider
      variant={variant || "fullWidth"}
      light={light}
      style={{ margin: `${space || 10}px 0px ${space || 10}px 0px` }}
    />
  );
}
