import PropTypes from "prop-types";

const DivExist = ({ children, show }) => {
  return <>{show && <>{children}</>}</>;
};

export default DivExist;

DivExist.propTypes = {
  show: PropTypes.bool,
};
