import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Paper } from "@material-ui/core";
export default function BackgroundPaper({
  children,
  darkLight,
  backgroundColor,
  style,
}) {
  const theme = createTheme({
    palette: { type: darkLight || "dark" },
  });
  return (
    <ThemeProvider theme={theme}>
      <Paper style={{ backgroundColor: backgroundColor, ...style }}>
        {children}
      </Paper>
    </ThemeProvider>
  );
}
