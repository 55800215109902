import firebase from "firebase/app";
import {
  authRef,
  firestoreRef,
  databaseRef,
  storageRef,
} from "../firebaseConfig";
import { asyncForEach, showMsg } from "./generalActions";
import FirebaseSM from "@ecoenghk/firebaseweb";

export const fs = new FirebaseSM();

export const loginFB = (email, password, dispatch) => {
  console.log("login firebase");
  authRef.signInWithEmailAndPassword(email, password).catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    if (errorCode === "auth/wrong-password") {
      showMsg(dispatch, "Wrong password.", "error");
    } else {
      showMsg(dispatch, errorMessage, "error");
    }
  });
};

export function logoutFB() {
  authRef.signOut();
  global.user = "";
}
export async function updateUserPasswordFB(newPassword, dispatch) {
  try {
    await authRef.currentUser.updatePassword(newPassword);
  } catch (e) {
    showMsg(dispatch, "Update password fail", "error");
    console.log(e);
  }
}
export async function fetchInitFB(uid, dispatch) {
  dispatch({
    type: "UPDATED_INIT_LOAD_PROGRESS",
    payload: {
      progress: 1,
      progressText: `Fetching map object data...`,
    },
  });
  const mapObjArray = await getDocsWhereFS(
    "SM_map",
    "inUser",
    "array-contains",
    uid,
    "array"
  );
  const mapObjAll = Object.fromEntries(
    mapObjArray.map((obj) => [obj.mapID, obj])
  );

  dispatch({
    type: "UPDATED_INIT_LOAD_PROGRESS",
    payload: {
      progress: 20,
      progressText: `Fetched map object all data...`,
    },
  });

  dispatch({
    type: "FETCHED_INIT",
    payload: {
      mapObjAll,
    },
  });
}

export async function fetchLightObjAll(mapIDArray) {
  let lightArrayAll = [],
    lightObjAll = {};
  await asyncForEach(mapIDArray, async (mapID) => {
    const lightObjArray = await getDocsWhereFS(
      "SM_serial",
      "mapID",
      "array-contains",
      mapID,
      "array"
    );
    lightArrayAll = [...lightArrayAll, ...lightObjArray];
  });
  lightArrayAll.forEach((obj) => (lightObjAll[obj.serial] = obj));
  return lightObjAll;
}
export async function fetchGatewayObjAll(mapIDArray) {
  let gatewayArrayAll = [],
    gatewayObjAll = {};
  await asyncForEach(mapIDArray, async (mapID) => {
    const gatewayObjArray = await getDocsWhereFS(
      "SM_gateway",
      "mapID",
      "array-contains",
      mapID,
      "array"
    );
    gatewayArrayAll = [...gatewayArrayAll, ...gatewayObjArray];
  });
  gatewayArrayAll.forEach((obj) => (gatewayObjAll[obj.gatewayID] = obj));
  return gatewayObjAll;
}
export async function fetchRepeaterObjAll(mapIDArray) {
  let arrayAll = [],
    objAll = {};
  await asyncForEach(mapIDArray, async (mapID) => {
    const arr = await getDocsWhereFS(
      "SM_repeater",
      "mapID",
      "array-contains",
      mapID,
      "array"
    );
    arrayAll = [...arrayAll, ...arr];
  });
  arrayAll.forEach((obj) => (objAll[obj.repeaterID] = obj));
  return objAll;
}

/////////////General get Firestore actions/////////////////////General get Firestore actions/////////////////////General get Firestore actions//////////

export const getDocFS = async (pathWithDocName) => {
  try {
    const result = await firestoreRef.doc(pathWithDocName).get();
    if (result.exists) {
      console.log("getDocFS success", result.data());
      return result.data();
    } else {
      console.log("doc not exist");
      return "fail";
    }
  } catch (err) {
    return "fail";
  }
};
export const getDocsWhereFS = async (
  pathEndWithCollection,
  whereField,
  compareOperator,
  compareVal,
  resultType,
  orderByField,
  order,
  limit,
  rangeField,
  upperRange,
  lowerRange
) => {
  try {
    // console.log({ rangeField, upperRange, lowerRange });
    let ref = firestoreRef.collection(pathEndWithCollection);
    if (whereField && compareOperator) {
      ref = ref.where(whereField, compareOperator, compareVal);
    }
    if (rangeField) {
      ref = ref
        .where(rangeField, "<=", upperRange)
        .where(rangeField, ">=", lowerRange);
    }
    if (orderByField && order) {
      ref = ref.orderBy(orderByField, order);
    }
    if (limit) {
      ref = ref.limit(limit);
    }

    const querySnapshot = await ref.get();
    let resultArray = [];
    let resultObj = {};
    querySnapshot.forEach((doc) => {
      resultArray = [...resultArray, doc.data()];
      resultObj[doc.id] = doc.data();
    });
    if (resultType === "object") {
      console.log("getDocsWhereFS obj", resultObj);
      return resultObj;
    } else {
      console.log("getDocsWhereFS array", resultArray);
      return resultArray;
    }
  } catch (err) {
    console.log(err);
    return resultType === "array" ? [] : {};
  }
};
export const getDocsByFieldArray = async (
  pathEndWithCollection,
  fieldName,
  fieldArray,
  orderByField,
  order,
  limit,
  rangeField,
  upperRange,
  lowerRange
) => {
  const chunk = (arr, n) =>
    arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : [];
  const arrInArr = chunk(fieldArray, 10);
  //   console.log("chunk array", arrInArr);
  let resultArray = [];
  await asyncForEach(arrInArr, async (arr) => {
    const result = await getDocsWhereFS(
      pathEndWithCollection,
      fieldName,
      "in",
      arr,
      "array",
      orderByField,
      order,
      limit,
      rangeField,
      upperRange,
      lowerRange
    );
    resultArray = [...resultArray, ...result];
  });
  return resultArray;
};
export const getDocsByFieldArrayAndWhere = async (
  pathEndWithCollection,
  fieldName,
  fieldArray,
  whereFieldArray,
  whereCompareValArray
) => {
  const chunk = (arr, n) =>
    arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : [];
  const arrInArr = chunk(fieldArray, 10);
  console.log("chunk array", arrInArr);
  let resultArray = [];
  await asyncForEach(arrInArr, async (arr) => {
    let ref = firestoreRef.collection(pathEndWithCollection);
    if (whereFieldArray?.length > 0 && whereCompareValArray.length > 0) {
      whereFieldArray.forEach(
        (f, i) => (ref = ref.where(f, "==", whereCompareValArray[i]))
      );
    }
    ref = ref.where(fieldName, "in", arr);
    const querySnapshot = await ref.get();
    querySnapshot.forEach((doc) => {
      //   console.log("got data", doc.data());
      resultArray = [...resultArray, doc.data()];
    });
  });
  return resultArray;
};
/////////////General Set Firestore actions////////////////////General Set Firestore actions////////////////////General Set Firestore actions//////////

export const addDocFS = async (pathWithDocName, obj) => {
  try {
    await firestoreRef.doc(pathWithDocName).set(obj);
    return "success";
  } catch (err) {
    return "fail";
  }
};
export const updateDocFS = async (pathWithDocName, obj) => {
  try {
    await firestoreRef.doc(pathWithDocName).update(obj);
    return "success";
  } catch (err) {
    return "fail";
  }
};
export const setDocFS = async (pathWithDocName, obj) => {
  try {
    await firestoreRef.doc(pathWithDocName).set(obj);
    return "success";
  } catch (err) {
    return "fail";
  }
};
export async function batchUpdateDocFS(
  pathEndWithCollection,
  docIDArray,
  updateObjArray
) {
  let batch = firestoreRef.batch();
  docIDArray.forEach((docID, key) => {
    const ref = firestoreRef.doc(`${pathEndWithCollection}/${docID}`);
    batch.update(ref, updateObjArray[key]);
  });
  try {
    await batch.commit();
    return "success";
  } catch (err) {
    return "fail";
  }
}
export const batchSetDocArrayFS = async (
  pathEndWithCollection,
  docArray,
  docIDField
) => {
  let batch = firestoreRef.batch();
  docArray.forEach((doc) => {
    const ref = firestoreRef.doc(pathEndWithCollection + "/" + doc[docIDField]);
    batch.set(ref, doc);
  });
  try {
    await batch.commit();
    return "success";
  } catch (err) {
    return "fail";
  }
};

export const deleteDocFS = async (pathWithDocName) => {
  try {
    await firestoreRef.doc(pathWithDocName).delete();
    return "success";
  } catch (err) {
    return "fail";
  }
};
export const deleteDocBatchFS = async (pathEndWithCollection, docIDArray) => {
  try {
    let batch = firestoreRef.batch();
    docIDArray.forEach((id) => {
      const ref = firestoreRef.doc(pathEndWithCollection + "/" + id);
      batch.delete(ref);
    });
    batch.commit();
    return "success";
  } catch (err) {
    return "fail";
  }
};
export const batchDeleteDocsFS = async (
  pathEndWithCollection,
  whereField,
  compareOperator,
  compareVal
) => {
  let ref = firestoreRef.collection(pathEndWithCollection);
  if (whereField && compareOperator) {
    ref = ref.where(whereField, compareOperator, compareVal);
  }
  const querySnapshot = await ref.get();
  let batch = firestoreRef.batch();
  querySnapshot.forEach((doc) => {
    const docId = doc.id;
    const deleteRef = firestoreRef.doc(pathEndWithCollection + "/" + docId);
    batch.delete(deleteRef);
  });
  try {
    await batch.commit();
    return "success";
  } catch (err) {
    return "fail";
  }
};
export const removeFieldValueFS = async (
  pathWithDocName,
  field,
  fieldValue
) => {
  try {
    await firestoreRef.doc(pathWithDocName).update({
      [`${field}.${fieldValue}`]: firebase.firestore.FieldValue.delete(),
    });
    return "success";
  } catch (err) {
    return "fail";
  }
};
export const removeFieldValueBatchFS = async (
  pathEndWithCollection,
  docIDArray,
  field,
  fieldValue
) => {
  try {
    let batch = firestoreRef.batch();
    docIDArray.forEach((id) => {
      const ref = firestoreRef.doc(pathEndWithCollection + "/" + id);
      batch.update(ref, {
        [`${field}.${fieldValue}`]: firebase.firestore.FieldValue.delete(),
      });
    });
    await batch.commit();
    return "success";
  } catch (err) {
    return "fail";
  }
};
export const removeArrayItemFS = async (pathWithDocName, field, item) => {
  try {
    await firestoreRef
      .doc(pathWithDocName)
      .update({ [field]: firebase.firestore.FieldValue.arrayRemove(item) });
    return "success";
  } catch (err) {
    return "fail";
  }
};
export const removeArrayItemBatchFS = async (
  pathEndWithCollection,
  docIDArray,
  field,
  item
) => {
  try {
    let batch = firestoreRef.batch();
    docIDArray.forEach((id) => {
      const ref = firestoreRef.doc(pathEndWithCollection + "/" + id);
      batch.update(ref, {
        [field]: firebase.firestore.FieldValue.arrayRemove(item),
      });
    });
    batch.commit();
    return "success";
  } catch (err) {
    return "fail";
  }
};
export const unionArrayItemFS = async (pathWithDocName, field, item) => {
  try {
    await firestoreRef
      .doc(pathWithDocName)
      .update({ [field]: firebase.firestore.FieldValue.arrayUnion(item) });
    return "success";
  } catch (err) {
    return "fail";
  }
};

////////database////////////////////database////////////////////database///////////////
export async function setDataRDB(childPath, setObj) {
  try {
    await databaseRef.child(childPath).set(setObj);
    return "success";
  } catch (e) {
    return "fail";
  }
}

export async function getDataRDB(childPath) {
  //   databaseRef
  //     .child(childPath)
  //     .once("value")
  //     .then((snapshot) => {
  //       console.log(snapshot.val());
  //       return snapshot.val();
  //     });
  try {
    const snapshot = await databaseRef.child(childPath).once("value");
    console.log(snapshot.val());
    return snapshot.val();
  } catch (e) {
    console.log(e);
    return "fail";
  }
}

////////////storage//////////////storage///////////storage////////////////storage//////////

export async function uploadImage(storagePath, file, metadata, dispatch) {
  const uploadTask = storageRef.child(storagePath).put(file, metadata);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
    },
    (error) => {
      console.log(error);
    },
    async () => {
      const url = await uploadTask.snapshot.ref.getDownloadURL();
      console.log("File available at", url);
      dispatch({
        type: "IMAGE_UPLOADED",
        payload: url,
      });
    }
  );
}
