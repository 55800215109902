import React, { useContext } from "react";
import { SMContext } from "../context/smContext";
import { Typography } from "@material-ui/core";

const MapName = ({ mapID, variant }) => {
  const [state] = useContext(SMContext);

  const { activeMapID, mapObjAll } = state;

  const mapName = mapID
    ? mapObjAll[mapID]?.mapName
    : mapObjAll[activeMapID]?.mapName;

  return (
    <Typography variant={variant ? variant : "body1"}>{mapName}</Typography>
  );
};

export default MapName;
