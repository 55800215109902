import React, { useState, useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, TextField } from "@material-ui/core";
import { ArrowUpward } from "@material-ui/icons";
import moment from "moment";
import DivExist from "components/DivExist";
import DialogInputDayOfMonth from "components/DialogInputDayOfMonth";
import DialogInputDateTime from "components/DialogInputDateTime";
import { updateDocLF } from "actions/dbActions";
import DialogInputScheduleTime from "./DialogInputScheduleTime";

export default function SchBox({ schObj, selected, big }) {
  const [state, dispatch] = useContext(SMContext);
  const { serverType, lightObjAll } = state;
  const [hover, setHover] = useState(false);
  const {
    no,
    reportType,
    schedule_timeStamp,
    rescheduleCount,
    scheduleID,
    serial,
  } = schObj;
  const description = lightObjAll[serial].description;
  const { backgroundColor, tooltipText } = getSchBoxColorText(reportType);
  const opacity =
    schedule_timeStamp > moment().tz("Asia/Hong_Kong").valueOf() ||
    !schedule_timeStamp
      ? 1
      : 0.5;
  //   const month = moment(schObj.schedule_timeStamp).format("MM");
  //   const year = moment(schObj.schedule_timeStamp).format("YY");
  const currentTS = moment().tz("Asia/Hong_Kong").valueOf();
  const nextMonthTS = moment().tz("Asia/Hong_Kong").add(1, "months").valueOf();
  function getSchBoxColorText(reportType) {
    let backgroundColor, tooltipText;
    switch (reportType) {
      case "check_6month": {
        backgroundColor = "#ffb3d9";
        tooltipText = "6-month test";
        break;
      }
      case "check_12month": {
        backgroundColor = "#ff6600";
        tooltipText = "Annual test";
        break;
      }
      case "check_36month": {
        backgroundColor = "#ff3300";
        tooltipText = "3-year test";
        break;
      }
      default: {
        backgroundColor = "#669999";
        tooltipText = "Monthly test";
      }
    }
    return { backgroundColor, tooltipText };
  }
  async function handleChangeScheduleTime(newTSP) {
    const obj = {
      schedule_timeStamp: newTSP,
      schedule_timeString: moment(newTSP).format("YYYY-MM-DD,HH:mm:ss"),
    };
    // console.log(obj);
    await updateDocLF(
      serverType,
      `SM_schedule/${scheduleID}`,
      "schedule",
      { scheduleID: scheduleID },
      obj
    );
    const newSchObj = { ...schObj, ...obj };
    dispatch({
      type: "UPDATED_SINGLE_TIME_SCHEDULE",
      payload: newSchObj,
    });
  }
  return (
    <div
      style={{
        minWidth: big || hover ? "8vw" : "2.6vw",
        marginRight: big || hover ? 4 : 2,
        marginLeft: big || hover ? 4 : 2,
      }}
    >
      <div
        onMouseOver={() => setHover(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseOut={() => setHover(false)}
        style={{
          backgroundColor,
          padding: 2,
          opacity,
          cursor: "pointer",
          border: selected ? "3px solid red" : "",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DivExist show={big}>
          <div style={{ padding: 2 }}>
            <Typography variant="subtitle1">{no}</Typography>
            <Typography>{reportType}</Typography>

            <Typography display="block" variant="caption" align="right">
              <DialogInputScheduleTime
                title={`${description} - ${no} ${reportType}`}
                initialVal={schedule_timeStamp || 0}
                handleSave={handleChangeScheduleTime}
              />

              {moment(schedule_timeStamp).format("YYYY-MMM-DD")}
            </Typography>
            <Typography display="block" variant="caption" align="right">
              {moment(schedule_timeStamp).format("HH:mm")}
            </Typography>

            {rescheduleCount > 0 && (
              <Typography display="block" variant="caption">
                Reschedule:{rescheduleCount}
              </Typography>
            )}
          </div>
        </DivExist>
        <DivExist show={hover && !big}>
          <div>
            <Typography variant="subtitle1">{no}</Typography>
            <Typography>{reportType}</Typography>
            <Typography display="block" variant="caption">
              {moment(schedule_timeStamp).format("YYYY-MMM-DD")}
            </Typography>
            <Typography display="block" variant="caption">
              {moment(schedule_timeStamp).format("HH:mm")}
            </Typography>
            {rescheduleCount > 0 && (
              <Typography display="block" variant="caption">
                Reschedule:{rescheduleCount}
              </Typography>
            )}
          </div>
        </DivExist>

        <DivExist show={!big && !hover}>
          <Typography variant="caption">{no}</Typography>
        </DivExist>
      </div>
      <DivExist
        show={
          currentTS < schedule_timeStamp && schedule_timeStamp < nextMonthTS
        }
      >
        <div style={{ textAlign: "center" }}>
          <ArrowUpward color="secondary" fontSize="small" />
        </div>
      </DivExist>
    </div>
  );
}
