import React, { useContext } from "react";
import { SMContext } from "../context/smContext";
import Snackbar from "@material-ui/core/Snackbar";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";

export default function ScreenMsg() {
  const [state, dispatch] = useContext(SMContext);
  const { screenMsg } = state;
  const { title, status, severity, time } = screenMsg;
  const handleClose = () => {
    dispatch({
      type: "CLOSED_SCREEN_MSG",
    });
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={status}
      autoHideDuration={time || 2500}
      onClose={handleClose}
      // message={<span>{title}</span>}
      action={
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      }
    >
      <Alert onClose={handleClose} severity={severity || "info"}>
        {title}
      </Alert>
    </Snackbar>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
