export default function DivCol({
  children,
  justifyContent,
  onClick,
  onMouseEnter,
  onMouseLeave,
  hide,
  style,
}) {
  return (
    <>
      {!hide ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: justifyContent || "flex-start",
            cursor: onClick ? "pointer" : "default",
            ...style,
          }}
          onClick={onClick}
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
        >
          {children}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
