export const mapModeStr = {
  normal: "normal",
  sceneEdit: "sceneEdit",
  sensorEdit: "sensorEdit",
  luxProfile: "luxProfile",
  lightConnect: "lightConnect",
  areaConnect: "areaConnect",
  multiSelect: "multiSelect",
  rssiLine: "rssiLine",
  rssiWeb: "rssiWeb",
  shiftSelect: "shiftSelect",
};
export const mapRightStr = {
  normal: "normal",
  light: "light",
  sensor: "sensor",
  gateway: "gateway",
  zoneControl: "zoneControl",
  repeater: "repeater",
  shiftSelected: "shiftSelected",
  rssiWeb: "rssiWeb",
};
let pwmSliderMarks = [];
for (let i = 0; i <= 100; i += 20) {
  const obj = { value: i, label: `${i}%` };
  pwmSliderMarks = [...pwmSliderMarks, obj];
}

let cursor = {
  pointer: "pointer",
  move: "move",
  default: "default",
  grab: "grab",
  notAllowed: "not-allowed",
};

let lightStyleMenu = {
  tube: "tube",
  owl: "owl",
  exit: "exit",
  indicator: "indicator",
  downlight: "downlight",
  panellight: "panellight",
};

export const sensorStyleMenu = {
  motion: "motion",
  lightswitch: "single light switch",
  lightswitchdouble: "double light switch",
  photo: "light",
  fire: "fire",
  breakglass: "breakglass",
  smoke: "smoke",
  nursecall: "nursecall",
  lightswitchreplicate: "Replicated button",
};
// export const sensorCodeOf = {
//   motion: 0,
//   photo: 1,
//   fire: 2,
//   breakglass: 3,
//   smoke: 4,
// };
const userLevel = {
  0: "superAdmin",
  1: "admin",
  2: "notify",
  3: "viewer",
};

const testType = {
  check_1month: "Monthly test",
  check_6month: "6-Month test",
  check_12month: "Yearly test",
  check_36month: "3-Year test",
  check_manual: "Manual test",
};

export { pwmSliderMarks, cursor, lightStyleMenu, userLevel, testType };

export const ppa104aEd4 = [
  ["Test type", "Battery discharge"],
  ["Monthly", "Less than 1/4 rated duration"],
  ["6-monthly", "1/4 rated duration"],
  ["3-yearly", "Fully discharge"],
];
export const ppa104aEd5 = [
  ["Test type", "Battery discharge"],
  ["Monthly", "Less than 10% rated duration"],
  ["Yearly", "Fully discharge"],
];

export const weekDayIsoMenu = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun",
};
export const dayMenu = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
export const hourMenu = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];
export const minuteMenu = {
  0: 0,
  5: 5,
  10: 10,
  15: 15,
  20: 20,
  25: 25,
  30: 30,
  35: 35,
  40: 40,
  45: 45,
  50: 50,
  55: 55,
};
// export const zoneControlIconMenu = {
//   default: "default",
//   greenPwr: "green power",
//   redPwr: "red power",
//   greyPwr: "grey power",
//   clock: "clock",
// };

export const batteryTypeMenu = {
  nimh: "Ni-Mh",
  nicd: "Ni-Cd",
  lipolymer: "Lithium polymer",
  liion: "Lithium ion",
  pbacid: "Lead-acid",
};
