import React, { useState, useEffect, useContext } from "react";
import { SMContext } from "../context/smContext";
import moment from "moment";

export const useGeneral = () => {
  const [state, dispatch] = useContext(SMContext);

  function showMessage(title) {
    dispatch({
      type: "OPENED_SCREEN_MSG",
      payload: title,
    });
  }

  function openDialog(title) {
    dispatch({
      type: "SWITCHED_DIALOG",
      payload: {
        status: true,
        title: title,
      },
    });
  }
  function closeDialog(title) {
    dispatch({
      type: "SWITCHED_DIALOG",
      payload: {
        status: false,
        title: title,
      },
    });
  }
  function openDataFetchProgress() {
    openDialog("FetchingDataDialog");
  }
  function closeDataFetchProgress() {
    closeDialog("FetchingDataDialog");
  }
  function displayVal(val) {
    let val_show;
    if (Array.isArray(val)) {
      //   val_show = `[${val.toString()}]`;
      val_show = JSON.stringify(val);
    } else {
      if (typeof val === "object") {
        val_show = JSON.stringify(val);
      } else if (typeof val === "boolean") {
        val_show = val ? "true" : "false";
      } else if (typeof val === "number") {
        val_show = val;
      } else if (typeof val === "string" && val === "") {
        val_show = "''";
      } else {
        val_show = val;
      }
    }

    return val_show;
  }
  function dataTypeOf(val) {
    let type;
    if (Array.isArray(val)) {
      type = "array";
    } else {
      type = typeof val;
    }
    return type;
  }
  function parseValue(newValue, dataType) {
    let parseVal;
    switch (dataType) {
      case "number":
        parseVal = Number(newValue);
        break;
      case "object":
        try {
          parseVal = JSON.parse(newValue);
        } catch (e) {
          alert(`value not object, example: {"aa":45, "bb":"ggg", "cc":true}`);
          return "parseFail";
        }
        break;
      case "array":
        try {
          parseVal = JSON.parse(newValue);
        } catch (e) {
          alert(`value not array, example: ["aa","bb","cc"]`);
          return "parseFail";
        }
        break;
      default:
        parseVal = newValue;
    }
    return parseVal;
  }
  function getTimeStringFromNow(timeStamp) {
    if (timeStamp) {
      const m = moment(timeStamp);
      const fn = m.fromNow();
      const str = `${m.format("YYYY-MM-DD,HH:mm:ss")}[${fn}]`;
      return str;
    } else {
      return "-";
    }
  }
  function generateRandomAddress4Byte() {
    const num = Math.floor(Math.random() * 254);
    const num1 = Math.floor(Math.random() * 254);
    const num2 = Math.floor(Math.random() * 254);
    const num3 = Math.floor(Math.random() * 254);
    let hex = num < 16 ? "0" + num.toString(16) : num.toString(16);
    let hex1 = num1 < 16 ? "0" + num1.toString(16) : num1.toString(16);
    let hex2 = num1 < 16 ? "0" + num2.toString(16) : num2.toString(16);
    let hex3 = num1 < 16 ? "0" + num3.toString(16) : num3.toString(16);
    const add = hex + hex1 + hex2 + hex3;
    return add;
  }
  function generateRandomAddressByte(byteQty) {
    let add = "";
    for (let i = 0; i < byteQty; i++) {
      const num = Math.floor(Math.random() * 254);
      let hex = num < 16 ? "0" + num.toString(16) : num.toString(16);
      add = add + hex;
    }
    return add;
  }
  function deviceNameOf(deviceID, deviceType) {
    const { lightObjAll, gatewayObjAll, repeaterObjAll, sensorObjAll } = state;
    const name =
      deviceType === "light"
        ? lightObjAll[deviceID]?.description
        : deviceType === "gateway"
        ? gatewayObjAll[deviceID]?.description
        : deviceType === "repeater"
        ? repeaterObjAll[deviceID]?.description
        : deviceType === "sensor"
        ? sensorObjAll[deviceID]?.sensorName
        : "wrong device type";
    return name;
  }
  function mapNameOf(mapID) {
    const { mapObjAll } = state;
    const name = mapObjAll[mapID]?.mapName;
    return name;
  }
  return {
    showMessage,
    openDialog,
    closeDialog,
    openDataFetchProgress,
    closeDataFetchProgress,
    displayVal,
    dataTypeOf,
    parseValue,
    getTimeStringFromNow,
    generateRandomAddress4Byte,
    generateRandomAddressByte,
    deviceNameOf,
    mapNameOf,
  };
};
