import React from "react";
import PropTypes from "prop-types";
import DivExist from "./DivExist";
import { lightIconOfType } from "../actions/generalActions";
import { Tooltip } from "@material-ui/core";
import DivInline from "components/DivInline";
import DivCol from "./DivCol";
import Downlight from "asset/svgComp/Downlight";
import Tube from "asset/svgComp/Tube";
import Panellight from "asset/svgComp/Panellight";
import Owl from "asset/svgComp/Owl";
import Exit from "asset/svgComp/Exit";
import moment from "moment";

export default function LightIcon({
  width,
  height,
  sizeUnit,
  lightObj,
  statusA,
  statusL,
  lightLv,
  lightStyle,
  typeBatVI,
  typeIsDim,
  connectStatus,
  disabledConnectStatus,
  disabledStatus,
  disabledControlL,
  tooltip,
  style,
  type,
  backgroundColor,
  timeStamp,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  const lightStyleUse = lightObj?.style || lightStyle;
  const statusA_use = lightObj?.currentData?.onoffstatusA ?? statusA ?? 1;
  const statusL_use = lightObj?.currentData?.onoffstatusL ?? statusL ?? 0;
  const lightLvUse = lightObj?.currentData?.lightLv ?? lightLv ?? 0;
  const typeBatVIUse = lightObj?.type?.batVI || typeBatVI;
  const typeIsDimUse = lightObj?.type?.pwm || typeIsDim;
  const timeStampUse = lightObj?.timeStamp || timeStamp;

  const latestDataTimeFromNow = moment().diff(moment(timeStampUse), "minutes");
  let connectStatusUse;
  if (connectStatus) {
    connectStatusUse = true;
  } else {
    if (!timeStampUse) {
      connectStatusUse = false;
    } else {
      if (timeStampUse && latestDataTimeFromNow >= 15) {
        connectStatusUse = false;
      } else if (
        timeStampUse &&
        latestDataTimeFromNow < 15 &&
        lightObj?.zigbeeConnected
      ) {
        connectStatusUse = true;
      }
    }
  }

  return (
    <div>
      <Tooltip title={tooltip || ""}>
        <div>
          {lightStyleUse === "downlight" ? (
            <Downlight
              width={width}
              sizeUnit={sizeUnit}
              statusA={statusA_use}
              statusL={statusL_use}
              lightLv={lightLvUse}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              typeBatVI={typeBatVIUse}
              typeIsDim={typeIsDimUse}
            />
          ) : lightStyleUse === "tube" ? (
            <Tube
              width={width}
              sizeUnit={sizeUnit}
              statusA={statusA_use}
              statusL={statusL_use}
              lightLv={lightLvUse}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              typeBatVI={typeBatVIUse}
              typeIsDim={typeIsDimUse}
            />
          ) : lightStyleUse === "panellight" ? (
            <Panellight
              width={width}
              sizeUnit={sizeUnit}
              statusA={statusA_use}
              statusL={statusL_use}
              lightLv={lightLvUse}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              typeBatVI={typeBatVIUse}
              typeIsDim={typeIsDimUse}
            />
          ) : lightStyleUse === "owl" ? (
            <Owl
              width={width}
              sizeUnit={sizeUnit}
              statusL={statusL_use}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
            />
          ) : lightStyleUse === "exit" ? (
            <Exit
              width={width}
              sizeUnit={sizeUnit}
              statusL={statusL_use}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
            />
          ) : (
            <img
              src={lightIconOfType(
                lightObj?.style || lightStyle,
                statusA_use,
                lightLvUse,
                disabledStatus
              )}
              alt=""
              style={{
                width: w + unit,
                height: h + unit,
                backgroundColor: backgroundColor,
                ...style,
              }}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
}

LightIcon.propTypes = {
  serial: PropTypes.string,
  style: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sizeUnit: PropTypes.string,
  status: PropTypes.oneOf([0, 1]),
  disabledStatus: PropTypes.bool,
};
