import React, { useState, useEffect } from "react";
import {
  TextField,
  //   MenuItem,
  //   Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import moment from "moment";
// import Divider10 from "./Divider10";
import { Edit, Done, Close } from "@material-ui/icons";
import PropTypes from "prop-types";

const DialogInputDateTime = ({ title, initialVal, handleSave, inputType }) => {
  const [open, setOpen] = useState(false);
  const [timeStamp, setTimeStamp] = useState(0);
  const closeDialog = () => setOpen(false);
  useEffect(() => {
    setTimeStamp(initialVal); //timestamp
  }, [initialVal]);

  const format =
    inputType === "date"
      ? "YYYY-MM-DD"
      : inputType === "datetime-local"
      ? "YYYY-MM-DDTHH:mm:ss"
      : inputType === "month"
      ? "YYYY-MM"
      : "YYYY-MM-DD";

  return (
    <React.Fragment>
      <Edit onClick={() => setOpen(!open)} style={{ cursor: "pointer" }} />
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={moment(timeStamp).format(format)}
            type={inputType}
            variant="outlined"
            onChange={evt =>
              setTimeStamp(moment(evt.target.value, format).valueOf())
            }
          />
        </DialogContent>
        <DialogActions>
          <IconButton onClick={closeDialog}>
            <Close />
          </IconButton>
          <IconButton
            onClick={() => {
              handleSave(timeStamp, moment(timeStamp).format(format));
              closeDialog();
            }}
          >
            <Done />
          </IconButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DialogInputDateTime;

//OUTSIDE USAGE:
// INPUT TIMESTAMP TO INITIAL VAL,
// HANDLE SAVE FUNCTION ARGUMENT SUPPLY EDITED TIMESTAMP AND TIMESTRING
{
  /* <DialogInputDateTime
  title="Date"
  initialVal={obj?.timeStamp}
  inputType="date"
  handleSave={(tsp, tsr) => {
    handleEditVal("timeString", key, tsr);
    handleEditVal("timeStamp", key, tsp);
  }}
/>; */
}

DialogInputDateTime.propTypes = {
  title: PropTypes.string.isRequired,
  initialVal: PropTypes.number.isRequired,
  handleSave: PropTypes.func.isRequired,
  inputType: PropTypes.oneOf(["date", "datetime-local", "month"])
};
