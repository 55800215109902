import moment from "moment";

export const smReducer = (state, action) => {
  switch (action.type) {
    case "SET_SERVER": {
      const { type, ip, id } = action.payload;
      return { ...state, serverIP: ip, serverType: type, serverID: id };
    }

    case "INITIAL_TEST_PASSED": {
      return { ...state, initialTestPassed: true };
    }

    case "LOGIN_APPROVED": {
      const userObj = action.payload;
      return { ...state, userObj: userObj, storedEmail: userObj.email };
    }

    case "UPDATED_INIT_LOAD_PROGRESS": {
      const { progress, progressText } = action.payload;
      return { ...state, progress, progressText };
    }
    case "OPENED_SCREEN_MSG": {
      return { ...state, screenMsg: { title: action.payload, status: true } };
    }
    case "SHOW_MSG": {
      const { title, severity, time } = action.payload;
      return { ...state, screenMsg: { title, severity, time, status: true } };
    }

    case "CLOSED_SCREEN_MSG": {
      return {
        ...state,
        screenMsg: { title: "", severity: "", status: false },
      };
    }

    case "SWITCHED_DIALOG": {
      const { title, status } = action.payload;
      return {
        ...state,
        dialogStatus: { ...state.dialogStatus, [title]: status },
      };
    }
    case "SWITCHED_MODAL": {
      const { title, status, x, y } = action.payload;
      return {
        ...state,
        modalStatus: {
          ...state.modalStatus,
          [title]: { status, x, y },
        },
      };
    }
    case "SHOW_BIG_PROGRESS": {
      return { ...state, showBigProgress: true };
    }
    case "HIDE_BIG_PROGRESS": {
      return { ...state, showBigProgress: false };
    }
    case "CLEAR_STATE_DATA": {
      const { dataName, dataType } = action.payload;
      const val =
        dataType === "object"
          ? {}
          : dataType === "array"
          ? []
          : dataType === "number"
          ? 0
          : "";
      return { ...state, [dataName]: val };
    }

    case "FETCHED_INIT": {
      const { mapObjAll } = action.payload;
      return {
        ...state,
        mapObjAll,
        initialLoaded: true,
      };
    }
    case "FETCHED_INIT_FB": {
      const type = action.payload;
      if (type === "reset") {
        return { ...state, initialLoadedFB: {} };
      } else {
        const iniObj = { ...state.initialLoadedFB, [type]: true };
        return { ...state, initialLoadedFB: iniObj };
      }
    }
    case "FETCHED_MAP_OBJ_ARRAY": {
      const mapObjArray = action.payload;
      const mapObjAll = Object.fromEntries(
        mapObjArray.map((obj) => [obj.mapID, obj])
      );
      return { ...state, mapObjAll };
    }

    case "FETCHED_LIGHT_OBJ_ALL": {
      return { ...state, lightObjAll: action.payload };
    }
    case "FETCHED_GATEWAY_OBJ_ALL": {
      return { ...state, gatewayObjAll: action.payload };
    }
    case "FETCHED_REPEATER_OBJ_ALL": {
      return { ...state, repeaterObjAll: action.payload };
    }

    case "FETCHED_SENSOR_OBJ_ALL": {
      return { ...state, sensorObjAll: action.payload };
    }
    case "FETCHED_SCHEDULE_OBJ_ALL": {
      return { ...state, scheduleObjAll: action.payload };
    }
    case "FETCHED_REPORT_ARRAY_IN_MAP": {
      return { ...state, reportArrayInMap: action.payload };
    }
    case "SET_ACTIVE_SERIAL": {
      return {
        ...state,
        activeSerial: action.payload,
      };
    }
    case "SET_ACTIVE_GATEWAY": {
      return { ...state, activeGatewayID: action.payload };
    }
    case "SET_ACTIVE_REPEATER": {
      return { ...state, activeRepeaterID: action.payload };
    }
    case "SET_ACTIVE_SENSOR": {
      return { ...state, activeSensorID: action.payload };
    }
    case "SET_ACTIVE_ZONE_CONTROL": {
      return { ...state, activeZoneControlID: action.payload };
    }

    case "SET_ACTIVE_MAP_OBJ": {
      return {
        ...state,
        activeMapObj: action.payload,
        activeMapID: action.payload.mapID,
      };
    }
    case "SET_ACTIVE_SCENE": {
      return { ...state, activeSceneID: action.payload };
    }
    case "UPDATED_LIGHT_OBJ": {
      const obj = action.payload;
      if (obj) {
        const serial = obj.serial;
        state.lightObjAll[serial] = obj;
        if (state.selectedDeviceObj) {
          state.selectedDeviceObj.disableButton = false;
        }
      }
      return { ...state };
    }
    case "UPDATED_MULTI_LIGHT_OBJ": {
      const changeObj = action.payload;
      let objAll = { ...state.lightObjAll, ...changeObj };
      const iniObj = { ...state.initialLoadedFB, light: true };
      return { ...state, lightObjAll: objAll, initialLoadedFB: iniObj };
    }
    case "UPDATED_GATEWAY_OBJ": {
      const obj = action.payload;
      if (obj) {
        const gatewayID = obj.gatewayID;
        state.gatewayObjAll[gatewayID] = obj;
      }
      return { ...state };
    }
    case "UPDATED_MULTI_GATEWAY_OBJ": {
      const changeObj = action.payload;
      let objAll = { ...state.gatewayObjAll, ...changeObj };
      const iniObj = { ...state.initialLoadedFB, gateway: true };
      return { ...state, gatewayObjAll: objAll, initialLoadedFB: iniObj };
    }
    case "UPDATED_GATEWAY_RSSI_MAP": {
      const pairItemObj = action.payload;
      const newRssiMap = { ...state.rssiMap, ...pairItemObj };
      return { ...state, rssiMap: newRssiMap };
    }
    case "UPDATED_REPEATER_OBJ": {
      const obj = action.payload;
      if (obj) {
        const repeaterID = obj.repeaterID;
        state.repeaterObjAll[repeaterID] = obj;
      }
      return { ...state };
    }
    case "UPDATED_SENSOR_OBJ": {
      const obj = action.payload;
      if (obj) {
        const sensorID = obj.sensorID;
        state.sensorObjAll[sensorID] = obj;
      }
      return { ...state };
    }
    case "UPDATED_MULTI_SENSOR_OBJ": {
      const changeObj = action.payload;
      let objAll = { ...state.sensorObjAll, ...changeObj };
      const iniObj = { ...state.initialLoadedFB, sensor: true };
      return { ...state, sensorObjAll: objAll, initialLoadedFB: iniObj };
    }
    case "EDITED_SENSOR_PROPERTY": {
      const { sensorID, field, val } = action.payload;
      const obj = state.sensorObjAll[sensorID];
      obj[field] = val;
      state.sensorObjAll[sensorID] = obj;
      return { ...state };
    }

    case "UPDATED_ZONECONTROL_OBJ": {
      const obj = action.payload;
      if (obj) {
        const zoneControlID = obj.zoneControlID;
        state.zoneControlObjAll[zoneControlID] = obj;
      }
      return { ...state };
    }
    case "UPDATED_MULTI_ZONECONTROL_OBJ": {
      const changeObj = action.payload;
      let objAll = { ...state.zoneControlObjAll, ...changeObj };
      const iniObj = { ...state.initialLoadedFB, zoneControl: true };
      return { ...state, zoneControlObjAll: objAll, initialLoadedFB: iniObj };
    }
    case "UPDATED_SERIAL_SCHEDULE_OBJ": {
      const { serial, data } = action.payload;
      state.scheduleObjAll[serial] = data || [];
      return { ...state };
    }
    case "UPDATED_SELECTED_LIGHT_OBJ": {
      const obj = action.payload;
      return { ...state, selectedDeviceObj: obj };
    }

    case "UPDATED_LIGHTS_IN_MAP": {
      const updatedObj = action.payload;
      const exObj = state.lightObjAll;
      const newObj = { ...exObj, ...updatedObj };
      return { ...state, lightObjAll: newObj };
    }
    case "UPDATED_GATEWAYS_IN_MAP": {
      const updatedObj = action.payload;
      const exObj = state.gatewayObjAll;
      const newObj = { ...exObj, ...updatedObj };
      return { ...state, gatewayObjAll: newObj };
    }
    case "UPDATED_SERIAL_NEXT_SCHEDULE_IN_MAP": {
      const { serial, schObj } = action.payload;
      state.nextScheduleObjAll[serial] = schObj;
      return { ...state };
    }

    case "REMOVE_LIGHT_FROM_MAP": {
      const serial = action.payload;
      delete state.activeMapObj.lightObj[serial];
      let mapIDArr = [...state.lightObjAll[serial].mapID];
      mapIDArr = mapIDArr.filter((mid) => mid !== state.activeMapID);
      state.lightObjAll[serial].mapID = mapIDArr;
      return { ...state };
    }
    case "REMOVE_SENSOR_FROM_MAP": {
      const sensorID = action.payload;
      delete state.activeMapObj.sensorObj[sensorID];
      let mapIDArr = [...state.sensorObjAll[sensorID].mapID];
      mapIDArr = mapIDArr.filter((mid) => mid !== state.activeMapID);
      state.sensorObjAll[sensorID].mapID = mapIDArr;
      return { ...state };
    }
    case "ADD_GATEWAY_TO_MAP": {
      const { gatewayID, gatewayObj } = action.payload;
      state.activeMapObj.gatewayObj[gatewayID] = { x: 120, y: 120 };
      state.gatewayObjAll[gatewayID] = gatewayObj;
      return { ...state };
    }

    case "REMOVE_GATEWAY_FROM_MAP": {
      const gatewayID = action.payload;
      delete state.activeMapObj.gatewayObj[gatewayID];
      delete state.gatewayObjAll[gatewayID];
      //   let mapIDArr = [...state.gatewayObjAll[gatewayID].mapID];
      //   mapIDArr = mapIDArr.filter((mid) => mid !== state.activeMapID);
      //   state.gatewayObjAll[gatewayID].mapID = mapIDArr;
      return { ...state };
    }
    case "REMOVE_REPEATER_FROM_MAP": {
      const repeaterID = action.payload;
      delete state.activeMapObj.repeaterObj[repeaterID];
      let mapIDArr = [...state.repeaterObjAll[repeaterID].mapID];
      mapIDArr = mapIDArr.filter((mid) => mid !== state.activeMapID);
      state.repeaterObjAll[repeaterID].mapID = mapIDArr;
      return { ...state };
    }

    case "REMOVED_ZONE_CONTROL_FROM_MAP": {
      const zoneControlID = action.payload;
      let obj = { ...state.zoneControlObjAll };
      delete obj[zoneControlID];
      return { ...state, zoneControlObjAll: obj };
    }
    case "CLEARED_RSSI_MAP": {
      return { ...state, rssiMap: {} };
    }
    case "IMAGE_UPLOADED": {
      return { ...state, uploadedImgUrl: action.payload };
    }
    case "CLEARED_UPLOADED_IMAGE": {
      return { ...state, uploadedImgUrl: "" };
    }
    case "SELECTED_MULTI": {
      const newObj = action.payload;
      const deviceID = newObj.deviceID;
      const idArray = state.selectMultiArray.map((obj) => obj.deviceID);
      let newArray = [...state.selectMultiArray];
      if (!idArray?.includes(deviceID)) {
        newArray = [...newArray, newObj];
      } else {
        newArray = newArray.filter((obj) => obj.deviceID !== deviceID);
      }
      return { ...state, selectMultiArray: newArray };
    }
    case "SELECT_MULTI_CLEAR": {
      return { ...state, selectMultiArray: [] };
    }
    case "SET_SCHEDULE_ARRAY_BY_SERIAL": {
      const scheduleArrayObjBySerial = action.payload;
      Object.keys(scheduleArrayObjBySerial).forEach((s) => {
        let arr = scheduleArrayObjBySerial[s];
        let newArr = [...(state.scheduleObjAll[s] || [])];
        const schIdArr = newArr.map((obj) => obj.scheduleID);
        const schTSRArr = newArr.map((obj) => obj.schedule_timeString);
        arr.forEach((obj) => {
          if (!schIdArr?.includes(obj.scheduleID)) {
            newArr = [...newArr, obj];
          } else if (!schTSRArr?.includes(obj.schedule_timeString)) {
            newArr = [...newArr, obj];
          }
        });
        state.scheduleObjAll[s] = newArr;
      });
      return { ...state };
    }
    case "DELETED_SINGLE_SCHEDULE": {
      const { serial, scheduleID, reportID } = action.payload;
      let newArr = [...state.scheduleObjAll[serial]];
      newArr = newArr.filter(
        (obj) => obj.scheduleID !== scheduleID && obj.reportID !== reportID
      );
      state.scheduleObjAll[serial] = newArr;
      return { ...state };
    }
    case "UPDATED_SINGLE_TIME_SCHEDULE": {
      const newSchObj = action.payload;
      const { scheduleID, serial, reportID } = newSchObj;
      let serialScheduleArr = state.scheduleObjAll[serial];
      let newSerialScheduleArr = serialScheduleArr?.map((obj) => {
        if (obj.scheduleID === scheduleID && obj.reportID === reportID) {
          return newSchObj;
        } else {
          return obj;
        }
      });
      state.scheduleObjAll[serial] = newSerialScheduleArr;
      return { ...state };
    }
    case "SET_SELECTED_DEVICE_GATEWAY": {
      const gatewayID = action.payload;
      const newObj = { ...state.selectedDeviceObj, gatewayID: gatewayID };
      return { ...state, selectedDeviceObj: newObj };
    }

    case "SHIFT_SELECT_CLICKED": {
      const s = action.payload;
      let arr = [...state.shiftSelectedArray];
      if (!arr.includes(s)) {
        arr = [...arr, s];
      } else {
        arr = arr.filter((a) => a !== s);
      }
      return { ...state, shiftSelectedArray: arr };
    }
    case "CLEARED_SHIFT_SELECT": {
      return { ...state, shiftSelectedArray: [] };
    }
    case "SET_MAP_RIGHT_MODE": {
      return { ...state, mapRightMode: action.payload };
    }
    case "SET_MAP_LAYER": {
      const obj = { ...state.mapLayer, ...action.payload };
      return { ...state, mapLayer: obj };
    }
    case "SET_MAP_LAYER_NORMAL": {
      const obj = { ...state.mapLayer };
      Object.keys(obj).forEach((k) => (obj[k] = false));
      obj.normal = true;
      return { ...state, mapLayer: obj };
    }
    case "SET_SELECTED_GATEWAY": {
      return { ...state, selectedGatewayID: action.payload };
    }
    case "SET_SELECTED_SENSOR": {
      return { ...state, selectedSensorID: action.payload };
    }
    //////////////SOCKET TO UI ACTIONS///////////////////////////////SOCKET TO UI ACTIONS////////////////////

    case "SOCKET_GW_ALIVE": {
      const {
        gatewayID,
        gatewayAliveTimeStamp,
        gatewayAliveTimeString,
        gatewayConnected,
        internal_serialMap,
        internal_repeatQueue,
        internal_disconnectCount,
      } = action.payload;
      const newObj = { ...state.gatewayObjAll[gatewayID] };
      state.gatewayObjAll[gatewayID] = {
        ...newObj,
        gatewayAliveTimeStamp,
        gatewayAliveTimeString,
        gatewayConnected,
        internal_serialMap,
        internal_repeatQueue,
        internal_disconnectCount,
      };
      return { ...state };
    }
    case "SOCKET_GW_START": {
      const { gatewayID, data } = action.payload;
      state.gatewayObjAll[gatewayID] = {
        ...state.gatewayObjAll[gatewayID],
        ...data,
      };
      return { ...state };
    }
    case "SOCKET_GW_ZIGBEE_RSSI": {
      const { zigbeeAddA, zigbeeAddB, rssi } = action.payload;
      const data = rssi ? rssi : 128;
      state.rssiMap[`${zigbeeAddA}-${zigbeeAddB}`] = data;
      return { ...state };
    }
    case "SOCKET_G0_GW_ACKNOWLEDGE_SINGLE_COMMAND": {
      const { serial, gwCommandID } = action.payload;
      let arr = state.gWCommandInProgress[serial] || [];
      arr = arr.filter((item) => item !== gwCommandID);
      state.gWCommandInProgress[serial] = arr;
      return { ...state };
    }
    case "SOCKET_D3_LIGHT_REGULAR": {
      const { serial, data } = action.payload;
      if (Object.keys(state.lightObjAll)?.includes(serial)) {
        state.lightObjAll[serial].currentData = data;
        state.lightObjAll[serial].zigbeeConnected = true;
        state.lightObjAll[serial].timeStamp = moment().valueOf();
        state.lightObjAll[serial].timeString = moment().format(
          "YYYY-MM-DD,HH:mm:ss"
        );
      }
      return { ...state };
    }
    case "SOCKET_D5_TEST_START": {
      const {
        serial,
        result,
        deviceTestStart_timeStamp,
        deviceTestStart_timeString,
        reportID,
      } = action.payload;
      if (state.lightObjAll[serial]) {
        let serialReportObj = { ...state.lightObjAll[serial].reportObj };
        serialReportObj = {
          ...serialReportObj,
          result,
          deviceTestStart_timeStamp,
          deviceTestStart_timeString,
        };
        state.lightObjAll[serial].reportObj = serialReportObj;
        if (
          serial === state.activeSerial &&
          reportID === state.activeReportID
        ) {
          state.activeReportObj = serialReportObj;
        }
        state.lightObjAll[serial].timeStamp = moment().valueOf();
        state.lightObjAll[serial].timeString = moment().format(
          "YYYY-MM-DD,HH:mm:ss"
        );
      }

      return { ...state };
    }
    case "SOCKET_D6_TEST_DATA_UPLOAD": {
      const { serial, data } = action.payload;
      const testTime = data.testTime;
      console.log(serial, data);
      if (state.lightObjAll[serial]) {
        state.lightObjAll[serial].timeStamp = moment().valueOf();
        state.lightObjAll[serial].timeString = moment().format(
          "YYYY-MM-DD,HH:mm:ss"
        );
        state.lightObjAll[serial].currentData = data;
        state.lightObjAll[serial].reportObj = {
          ...state.lightObjAll[serial]?.reportObj,
          ...data,
        };
        if (
          serial === state.activeSerial &&
          data.reportID === state.activeReportID
        ) {
          state.singleReportDataArray = [...state.singleReportDataArray, data];

          state.activeReportObj = {
            ...state.activeReportObj,
            testTime,
          };
        }
      }

      return { ...state };
    }
    case "SOCKET_D7_TEST_FINISH_UPLOAD": {
      const {
        serial,
        result,
        deviceTestEnd_timeStamp,
        deviceTestEnd_timeString,
        totalTestTime,
        schedule_timeStamp,
        reportID,
      } = action.payload;
      if (state.lightObjAll[serial].reportObj) {
        state.lightObjAll[serial].reportObj = {
          ...state.lightObjAll[serial]?.reportObj,
          result,
          deviceTestEnd_timeStamp,
          deviceTestEnd_timeString,
          totalTestTime,
          schedule_timeStamp,
        };
      }

      if (serial === state.activeSerial && reportID === state.activeReportID) {
        state.activeReportObj = {
          ...state.activeReportObj,
          result,
          deviceTestEnd_timeStamp,
          deviceTestEnd_timeString,
          totalTestTime,
          schedule_timeStamp,
        };
      }
      return { ...state };
    }
    case "SOCKET_D8_LIGHT_RESPONSE": {
      const { serial, data } = action.payload;
      if (Object.keys(state.lightObjAll)?.includes(serial)) {
        state.lightObjAll[serial].currentData = data;
        state.lightObjAll[serial].zigbeeConnected = true;
        state.lightObjAll[serial].timeStamp = moment().valueOf();
        state.lightObjAll[serial].timeString = moment().format(
          "YYYY-MM-DD,HH:mm:ss"
        );
        state.selectedDeviceObj.disableButton = false;
      }
      return { ...state };
    }
    case "SOCKET_81_SENSOR_UPLOAD_DATA": {
      const { sensorID, socketInData } = action.payload;
      const { currentStatus, lux, fire, timeString, timeStamp } = socketInData;
      const updateObj = { currentStatus, lux, fire, timeString, timeStamp };
      let sensorObj = { ...state.sensorObjAll[sensorID], ...updateObj };
      const newSensorObjAll = { ...state.sensorObjAll, [sensorID]: sensorObj };
      return { ...state, sensorObjAll: newSensorObjAll };
    }
    case "SOCKET_84_SENSOR_START": {
      const { sensorID, socketInData } = action.payload;
      const { deviceStartTimeStamp, deviceStartTimeString, deviceVersion } =
        socketInData;
      const updateObj = {
        deviceStartTimeStamp,
        deviceStartTimeString,
        deviceVersion,
      };
      let sensorObj = { ...state.sensorObjAll[sensorID], ...updateObj };
      const newSensorObjAll = { ...state.sensorObjAll, [sensorID]: sensorObj };
      return { ...state, sensorObjAll: newSensorObjAll };
    }
    case "SOCKET_S1_ANALYSED_TEST_FINISHED": {
      const { serial, data } = action.payload;
      const {
        reportID,
        result,
        deviceTestEnd_timeStamp,
        deviceTestEnd_timeString,
        totalTestTime,
        resultData,
      } = data;
      const updatedObj = {
        ...state.lightObjAll[serial]?.reportObj,
        result,
        resultData,
      };
      state.lightObjAll[serial].reportObj = updatedObj;
      state.lightObjAll[serial].timeStamp = moment().valueOf();
      state.lightObjAll[serial].timeString = moment().format(
        "YYYY-MM-DD,HH:mm:ss"
      );
      state.reportArrayInMap = [...state.reportArrayInMap, updatedObj];
      if (serial === state.activeSerial && reportID === state.activeReportID) {
        state.activeReportObj = {
          ...state.activeReportObj,
          result,
          deviceTestEnd_timeStamp,
          deviceTestEnd_timeString,
          totalTestTime,
          resultData,
        };
      }
      return { ...state };
    }
    case "SOCKET_GW_UPDATED": {
      const { gatewayID, updateData } = action.payload;
      state.gatewayObjAll[gatewayID] = {
        ...state.gatewayObjAll[gatewayID],
        ...updateData,
      };
      return { ...state };
    }
    case "SOCKET_SERIAL_RECONNECT": {
      const { serial, data } = action.payload;
      state.lightObjAll[serial] = { ...state.lightObjAll[serial], ...data };
      return { ...state };
    }
    case "SOCKET_SERIAL_DISCONNECT": {
      const { serial, data } = action.payload;
      state.lightObjAll[serial] = { ...state.lightObjAll[serial], ...data };
      return { ...state };
    }
    case "SOCKET_FOUND_DOCS": {
      const { fetchDataName, result } = action.payload;
      state[fetchDataName] = result;
      return { ...state };
    }
    case "SOCKET_UPDATE_LIGHT": {
      const obj = action.payload;
      if (obj) {
        const serial = obj.serial;
        state.lightObjAll[serial] = obj;
        state.lightObjAll[serial].timeStamp = moment().valueOf();
        state.lightObjAll[serial].timeString = moment().format(
          "YYYY-MM-DD,HH:mm:ss"
        );
      }
      return { ...state };
    }
    //////////////SOCKET TO UI ACTIONS///////////////////////////////SOCKET TO UI ACTIONS////////////////////

    case "SET_MAP_MODE": {
      return { ...state, mapMode: action.payload };
    }
    case "ALL_UNSELECTED": {
      return {
        ...state,
        activeSerial: "",
        activeGatewayID: "",
        activeRepeaterID: "",
        activeReportID: "",
        activeSensorID: "",
        selectedID: "",
        selectedDeviceType: "",
        selectedDeviceObj: {},
        mapRightMode: "normal",
        selectedGatewayID: "",
        selectedSensorID: "",
      };
    }
    case "DEVICE_SELECTED": {
      const { deviceID, deviceType, deviceObj } = action.payload;
      return {
        ...state,
        selectedID: deviceID,
        selectedDeviceType: deviceType,
        selectedDeviceObj: deviceObj,
      };
    }

    case "UPDATED_MAP_OBJ": {
      const newMapObj = action.payload;
      const mapID = newMapObj?.mapID;
      state.activeMapObj = newMapObj;
      state.mapObjAll[mapID] = newMapObj;
      return { ...state };
    }
    case "UPDATED_MAP_DEVICE_POSITION": {
      const { deviceType, deviceID, mapID, x, y } = action.payload;
      state.activeMapObj[deviceType][deviceID].x = x;
      state.activeMapObj[deviceType][deviceID].y = y;
      state.mapObjAll[mapID][deviceType][deviceID].x = x;
      state.mapObjAll[mapID][deviceType][deviceID].y = y;
      return { ...state };
    }
    case "SET_SINGLE_REPORT_DATA_ARRAY": {
      return { ...state, singleReportDataArray: action.payload };
    }

    case "SET_ACTIVE_REPORT": {
      const reportObj = action.payload;
      return {
        ...state,
        activeReportObj: reportObj,
        activeReportID: reportObj?.reportID,
      };
    }
    case "SET_SERIAL_REPORT_OBJ": {
      const { serial, newReportObj } = action.payload;
      state.lightObjAll[serial].reportObj = newReportObj;
      return { ...state };
    }
    case "UPDATED_SERIAL_PROPERTY": {
      const { serial, serialProp } = action.payload;
      state.lightObjAll[serial] = {
        ...state.lightObjAll[serial],
        ...serialProp,
      };
      return { ...state };
    }

    case "REMOVED_MAP": {
      const mapID = action.payload;
      delete state.mapObjAll[mapID];
      return { ...state };
    }
    case "SET_MAP_DISPLAY_INFO": {
      const { infoType, status } = action.payload;
      state.mapDisplayInfo[infoType] = status;
      return { ...state };
    }

    case "SET_ACTIVE_SCHEDULE_OBJ": {
      return { ...state, activeScheduleObj: action.payload };
    }
    case "UPDATED_SINGLE_SCHEDULE": {
      const { serial, schObj } = action.payload;
      const { scheduleID, reportID } = schObj;
      let schObjArray = state.scheduleObjAll[serial];
      const schIDArray = schObjArray?.map((obj) => obj.scheduleID);
      const reportIDArray = schObjArray?.map((obj) => obj.reportID);
      if (schIDArray && reportIDArray) {
        if (
          !schIDArray?.includes(scheduleID) &&
          !reportIDArray?.includes(reportID)
        ) {
          schObjArray = [...schObjArray, schObj];
          state.scheduleObjAll[serial] = schObjArray;
        }
      }

      return { ...state };
    }
    case "SET_ENABLE_PAN": {
      return { ...state, enablePan: action.payload };
    }
    case "SET_STAGE_SCALE": {
      return { ...state, stageScale: action.payload };
    }
    case "CLOSED_MAP": {
      let newLayerObj = {};
      Object.keys(state.mapLayer).forEach((field) => {
        if (field === "normal") {
          newLayerObj[field] = true;
        } else {
          newLayerObj[field] = false;
        }
      });
      return {
        ...state,
        activeMapObj: {},
        activeMapID: "",
        lightObjAll: {},
        gatewayObjAll: {},
        sensorObjAll: {},
        zoneControlObjAll: {},
      };
    }

    ////////general use////////////////////general use////////////////////general use////////////
    case "FETCHED_COLLECTION_LIST": {
      return {
        ...state,
        collectionList: action.payload,
      };
    }
    case "FETCHED_COLLECTION_ITEMS": {
      return { ...state, collectionItems: action.payload };
    }
    case "SET_ACTIVE_COLLECTION": {
      return { ...state, activeCollection: action.payload };
    }
    case "SET_ACTIVE_ITEM": {
      return { ...state, activeItem: action.payload };
    }

    case "FETCHED_SERIAL_ALL": {
      return { ...state, lightObjArrayOverAll: action.payload };
    }
    case "GW_COMMAND_SINGLE_ADD_QUEUE": {
      const { serial, gwCommand } = action.payload;
      let arr = state.gWCommandInProgress[serial] || [];
      if (!arr?.includes(gwCommand)) {
        arr = [...arr, gwCommand];
      }
      state.gWCommandInProgress[serial] = arr;
      return { ...state };
    }

    case "TEMP_CHANGED_TO_SUPER_ADMIN": {
      const newUserObj = { ...state.userObj, level: 0 };
      return { ...state, userObj: newUserObj };
    }
    case "RESET_REDUCER":
      const storedEmail = state.storedEmail;
      const serverIP = state.serverIP;
      const serverType = state.serverType;
      const serverID = state.serverID;
      return { ...initialState, storedEmail, serverIP, serverType, serverID };
    default:
      return state;
  }
};

export const initialState = {
  serverIP: "",
  serverType: "unknown",
  serverID: "",
  initialLoaded: false,
  initialLoadedFB: {},
  progress: 0,
  progressText: "",
  screenMsg: {
    title: "",
    status: false,
  },
  userObj: {
    initialTestPassed: false,
    loginApproved: false,
    email: "",
    password: "",
    uid: "",
  },
  storedEmail: "",
  mapObjAll: {},
  gatewayObjAll: {},
  lightObjAll: {},
  repeaterObjAll: {},
  sensorObjAll: {},
  zoneControlObjAll: {},
  scheduleObjAll: [],
  nextScheduleObjAll: {},
  activeGroup: "",
  activeMapID: "",
  activeMapObj: {},
  activeMapScale: { x: 1, y: 1 },
  activeSubGroupID: "",
  activeSceneID: "",
  activeSerial: "",
  activeGatewayID: "",
  activeRepeaterID: "",
  activeSensorID: "",
  activeZoneControlID: "",
  activeReportID: "",
  activeReportObj: {},
  activeScheduleObj: {},
  mapMode: "normal",
  enablePan: true,
  stageScale: 1,
  mapLayer: {
    normal: true,
    gatewaySelect: false,
    sensorSelect: false,
    lockLevel: false,
    rssiWeb: false,
    light: false,
    sensor: false,
    gateway: false,
    luxProfile: false,
    // shiftSelect:false
    serialNo: false,
    deviceName: false,
    zigbeeAdd: false,
  },
  mapRightMode: "normal",
  selectedID: "",
  selectedDeviceType: "",
  selectedDeviceObj: {},
  selectMultiArray: [],
  selectedGatewayID: "",
  selectedSensorID: "",
  shiftSelectedArray: [],

  mapDisplayInfo: {
    id: false,
    description: false,
    dataTime: false,
    zigbeeAdd: false,
  },
  showBigProgress: false,
  rssiMap: {},
  rssiCount: [],
  uploadedImgUrl: "",
  //   dialogStatus: {
  //     /////admin//////
  //     FetchingDataDialog: false,
  //     DialogLoading: false,
  //     DBAdminDialog: false,
  //     SMAdminDialog: false,
  //     AdminScheduleDialog: false,
  //     AdminGroupReportDialog: false,
  //     UserDialog: false,
  //     SerialListDialog: false,
  //     InsertItemDialog: false,
  //     CollectionItemsDialog: false,
  //     EditItemDialog: false,

  //     /////app use////////
  //     AllDevicesListDialog: false,
  //     MapDialog: false,
  //     MapSettingDialog: false,
  //     AddDeviceToMapDialog: false,
  //     AddSceneToMapDialog: false,
  //     LightChangeGatewayDialog: false,
  //     DeviceListDialog: false,
  //     GatewayDetailDialog: false,
  //     LightReportListDialog: false,
  //     TestViewDialog: false,
  //     SingleReportDialog: false,
  //     TestScheduleDialog: false,
  //     GroupReportDialog: false,
  //     UploadImageDialog: false,
  //     UploadImageFBDialog: false,
  //     AddMapDialog: false,
  //     EditScenePropertyDialog: false,
  //     ManualCheckStartDialog: false,
  //     EditScheduleDialog: false,
  //     LightEditScheduleDialog: false,
  //     BatchEditScheduleDialog: false,
  //     EditSingleScheduleDialog: false,
  //     CheckRSSIDialog: false,
  //     AllLightReportDialog: false,
  //     EditReportEmailDialog: false,
  //     ServerInfoDialog: false,
  //     SensorDetailDialog: false,
  //     EditTimeControlDialog: false,
  //     TransferSerialDialog: false,
  //     SensorChangeGatewayDialog: false,
  //     ZoneControlDetailDialog: false,
  //   },
  reportArrayInMap: [],
  singleReportDataArray: [],

  gWCommandInProgress: {},

  //   modalStatus: {
  //     LightCommandModal: { status: false, x: 0, y: 0 },
  //     // SensorLightSettingModal: { status: false, x: 0, y: 0 },
  //     // SceneAddLightModal: { status: false, x: 0, y: 0 }
  //   },

  ////////general use////////////
  //   activeCollection: "",
  //   activeItem: {},
  //   collectionList: [],
  //   collectionItems: [],

  lightObjArrayOverAll: [],
  serialLogList: [],
};
