const DivInline = ({
  children,
  justifyContent,
  alignItems,
  onClick,
  onMouseEnter,
  onMouseLeave,
  hide,
  style,
}) => {
  return (
    <>
      {!hide && (
        <div
          style={{
            display: "flex",
            alignItems: alignItems || "center",
            justifyContent: justifyContent || "flex-start",
            cursor: onClick ? "pointer" : "default",
            ...style,
          }}
          onClick={onClick}
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default DivInline;
