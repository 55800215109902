import IconButton from "@material-ui/core/IconButton";
import { Save } from "@material-ui/icons";

const IconButtonSave = ({ onBtnClick, disabled }) => {
  const handleAction = () => {
    onBtnClick();
  };

  return (
    <IconButton onClick={() => handleAction()} disabled={disabled}>
      <Save />
    </IconButton>
  );
};

export default IconButtonSave;
