// global.build = 142;
global.serverType = "";
global.serverIP = "";
global.serverID = "";
global.user = "";

//Each update, 1. push github, 2. npm version major|minor|patch,
//3. npm run build, 4 deploy firebase hosting, 5 copy to local server

//FB hosting(cloud ver only):   firebase deploy --only hosting:skymon
//ftp to office server: 218.255.237.182 port 200  (skymon.info)
//login ftp using "skymon"
//copy to folder "skymon_localserver_webapp/build"
// convert svg to jsx file: https://svg2jsx.com/

/*
From 28Jul21, change to use package.json version for version control
142 bug fix. minor revision
141 bug fix. minor revision
140 listen devices dispatch in one time rather than each device.  batch edit sensor light settings.
139 change all major page to react router. rearrange some folder.  add shift select.
138 modify test schedule dialog. give a try to react router, to be continued
137 minor revision
136 minor revision
135 minor revision. Add permanent delete light.
134 minor revision
133 reopen regular time interval. minor revision
132 minor revision
131 png icon changed to svg icons (map use and general use)
130 modify test schedule. ZC timer.
129 light change zigbee also change sensor/zc settings, serail add sensorArr/zcArray, sensor check connect. sensor history modal
128 Minor revision
127 Add gateway network info. Light,sensor copy to other map.  Minor revision.
126 Add lock level setting dialog(sensor part). 
125 minor revision
124 minor revision
123 minor revision
122 Add 2P light swtich. 
121 Add new RSSI web
120 minor revision
119 update light switch sensor and light settings.
118 add zone control - delay. Not finished
117 modify light connect gateway.  Add timer control map modal. 
116 Revise add sensor and change light setting. Add timer and dimming zone control, add create zone control.
115 modify device change gateway.  Started revise zone control and sensor light settings.
114 Add light switch sensor. Bug fix.
113 Add light style-panel light. Dialog can change background color.  
112 Modify light history.  
111 Light change wifi/zigbee in admin level.  Delete 3s light switch delay. Add GW000000 wifi virtual gateway choice.
110 Timer setting. Wifi light manual actions. Sensor light setting.
109 Lock/unlock manual.
108 Add zonne control toolbar and delete zone conntrol.
107 Edit minV minI in edit single schedule.  Localserver, on map start, only fetch next schedule, cancel fetch scheduleAll.
106 Add zone control. Add lock level to lights.
105 Sensor history add system start. Add history timeline for light. Add input for survival time interval, regular interval, survival timeout
104 Fix sensor setting bug. Sensor detail separate panels.  History panel add timeline.
103 ReverseA set 0/1 instead of true/false.  Sensor manual send start up setting. Separate layer for idle lights and use animation for idle lights.
102 Modify scene detail.  Fix reverseI in test view dialog.  Lights connect status in gateway detail.
101 Add scene detail. Add gateway search idle light button. Add weekday for each time control.
100 Fix sensor change zigbee add bug. Add scene toolbar.
99 fix mobile map scene arrangement.  Add set reverse A. Add input light battery type and capacity.
98 Add light icon for 30/70 level. Rearrange light detail. Add tablet version - no footer for tablet. Add change user to superadmin.
97 Add sensor change zigbee.  Add gateway log in detail. Add search device in Home. 
96 fix light toolbar switch. Sensor detail add comm & version.  Started gatewayLog in gateway detail, not finished.  
95 merge light action to lightActionLF.  Light detail add manual A4 A2 button.
94 add force remove light in map setting.  add gateway internal info display. Fix FB ver rssi line display.  Add dim transition setting.
93 fix edit add single schedule bug.  gateway-light lines follow gateway serialMap.  Add mobile control page
92 partly add schedule add reportID.
91 fix sensor detail bug. 
90 change serial property minV minI also update minV minI in all schedules
89 sensor detail add restart and send setting and edit motion off delay, intervals.
88 make light switch button disabled after press and then resume.  Add partly edit schedule
87 Group report search by scheduleMonth & year. 
86 Fix add light to sensor bug. Add set zone control icon. Add scheduleYear scheduleMonth to schedule
85 Add downlight icon. Add light fault info on map and detail. Set connect fault when gateway & light restart.
84 Add filter report list by month, delete report
83 weekly test setting bug fix, download excel file add map name and date. 
82 map test time NaN bug fix, add serialArray in user, serialArray change when add/remove light from map
81 add map right alarm list panel, modify sensor detail dialog, sensor data history log, add map array in user
80 bug fix, add partial change light schedules
79 Start socket in for sensor, not finished.  All nursecall button and indicator light
78 Admin user can modify all user, not only fellow user,  add weektest setting batch set,  add combined fb ls functions, add transfer settings/report from other serial  
77 modify upload excel to schedule table to individual dates.  add filter weekly test report
76 add upload excel to email table,  single light report modified, add week test setting
75 set light disconnect when disconnect from gateway, add email setting of light operation fail, bug fix
74 fix sensor type and add smoke & breakglass icon
73 modify map create and setting in first create
72  add wifi light connect status, manual test start, modify test report, add weekly test light setting
71  add battery current polarity setting, enable lux type setting, add super admin light setting, add latest data refresh button
	map device list made to not realtime,
70  add multi select email add to serial, change some moment to moment-timezone
69  bug fix, test schedule in 5 min interval
68	bug fix, add localserver ip for fsd local 192.168.1.200	

*/
