import React, { useState, useContext, lazy } from "react";
// import { SMContext } from "../../context/smContext";
import {
  Typography,
  Button,
  Paper,
  Tooltip,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import DialogSM from "../../components/DialogSM";
import Divider10 from "../../components/Divider10";
import DivExist from "../../components/DivExist";
// import { useGeneral } from "../../hooks/generalHooks";
import ShatinHospitalphoto from "../../asset/image/ShatinHospitalphoto.jpg";

export default function ServerSelect() {
  //   const [state, dispatch] = useContext(SMContext);
  //   const { openDialog, closeDialog, showMessage } = useGeneral();
  const blockStyle = { width: 240, height: 240, margin: 10 };
  const cardMediaHeight = 170;
  return (
    <Paper style={{ background: "#34495E", minHeight: "100vh" }}>
      <Typography>Skymon Smart Light System for</Typography>
      <Typography>EMSD</Typography>
      <Typography>
        at New Territories East Cluster Hospital Authority
      </Typography>
      <Divider10 />
      <div
        style={{ display: "flex", flexWrap: "wrap", width: "90%", padding: 50 }}
      >
        <Card
          style={blockStyle}
          onClick={() => {
            window.open("http://skymon.info/sh", "_self");
          }}
        >
          <CardActionArea>
            <CardMedia
              style={{ height: cardMediaHeight }}
              image={ShatinHospitalphoto}
            />
            <CardContent>
              <Typography gutterBottom variant="h6">
                Shatin Hospital
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </Paper>
  );
}
