import React from "react";
// import { SMContext } from "../../context/smContext";
import {
  Paper,
  //   Tooltip,
  //   Typography,
  Modal,
  //   IconButton,
} from "@material-ui/core";
// import { Add, Delete } from "@material-ui/icons";
import Divider10 from "./Divider10";
import IconButtonClose from "./IconButtonClose";
import { dialogBg } from "asset/string/color";
import IconButtonSave from "./IconButtonSave";

export default function ModalSM({
  children,
  open,
  onClose,
  backgroundColor,
  minWidth,
  minHeight,
  disableBottomClose,
  maxHeight,
  maxWidth,
  onSave,
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        style={{
          position: "absolute",
          background: backgroundColor || dialogBg,
          minWidth: minWidth || 400,
          minHeight: minHeight || 200,
          padding: 10,
          top: "50%",
          left: "50%",
          boxShadow: "2px 3px darkgrey",
          transform: "translate(-50%,-50%)",
          maxHeight: maxHeight || "",
          maxWidth: maxWidth || "",
        }}
      >
        {children}
        {!disableBottomClose && (
          <>
            <Divider10 />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButtonClose onBtnClick={onClose} />
              {onSave && <IconButtonSave onBtnClick={onSave} />}
            </div>
          </>
        )}
      </Paper>
    </Modal>
  );
}
