import React, { useState, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Typography, Tooltip, Button } from "@material-ui/core";
import { SMContext } from "context/smContext";
import { Edit, Event, Room } from "@material-ui/icons";
import moment from "moment-timezone";
import { useGeneral } from "hooks/generalHooks";
import { getDocsByFieldArray } from "actions/firebaseActions";
import Divider10 from "components/Divider10";
import Space10 from "components/Space10";
import DivInline from "components/DivInline";
import MapName from "components/MapName";
import BackgroundPaper from "components/BackgroundPaper";
import LightIcon from "components/LightIcon";
import SchBox from "./testSchedule/SchBox";

export default function TestPage() {
  const [state, dispatch] = useContext(SMContext);
  const location = useLocation();
  let history = useHistory();
  const { activeMapID, lightObjAll, scheduleObjAll, serverType, userObj } =
    state;
  useEffect(() => {
    console.log(location?.state?.detail);
    fetchScheduleFB();
  }, [location]);
  async function fetchScheduleFB() {
    const lightArray = Object.keys(lightObjAll)
      .filter((s) => lightObjAll[s]?.type?.batVI === true)
      .filter((s) => lightObjAll[s]?.mapID?.includes(activeMapID));

    const arr = await getDocsByFieldArray("SM_schedule", "serial", lightArray);
    let scheduleObjAll = {};
    lightArray.forEach((s) => {
      scheduleObjAll[s] = [];
    });
    arr.forEach((obj) => {
      const serial = obj.serial;
      scheduleObjAll[serial].push(obj);
    });
    dispatch({
      type: "FETCHED_SCHEDULE_OBJ_ALL",
      payload: scheduleObjAll,
    });
  }
  const lightArray = Object.keys(scheduleObjAll || {});
  function handleLightBatchEdit(serial) {
    dispatch({
      type: "SET_ACTIVE_SERIAL",
      payload: serial,
    });
    //   openDialog("LightEditScheduleDialog");
  }
  return (
    <BackgroundPaper darkLight="dark" backgroundColor="#002">
      <Button
        onClick={() => {
          history.push("/");
        }}
      >
        back
      </Button>
      <DivInline>
        <Typography variant="h6">Map</Typography>
        <Room />
        <Space10 />
        <MapName variant="h6" />
        <Space10 />
      </DivInline>
      <Divider10 />
      <div style={{ width: "100%" }}>
        {lightArray
          .sort((a, b) =>
            lightObjAll[a].description.localeCompare(lightObjAll[b].description)
          )
          .map((s) => {
            const lightObj = lightObjAll[s];
            const {
              enableWeekTest,
              weekTestWeekday,
              weekTestHour,
              weekTestMin,
            } = lightObj || {};
            return (
              <div key={s}>
                <DivInline>
                  <LightIcon
                    lightStyle={lightObj?.style}
                    disabledStatus
                    disabledConnectStatus
                    width={20}
                  />
                  <Space10 />
                  <Typography>{lightObj?.description}</Typography>
                  <Space10 />
                  <Typography variant="caption">[{s}]</Typography>
                  <Tooltip
                    title={
                      <Typography>
                        Batch set test schedule time of light [{s}]
                      </Typography>
                    }
                  >
                    <Edit
                      style={{ cursor: "pointer" }}
                      onClick={() => handleLightBatchEdit(s)}
                    />
                  </Tooltip>
                </DivInline>
                {scheduleObjAll[s]?.length > 0 ? (
                  <div
                    style={{ display: "flex", marginTop: 10, marginBottom: 20 }}
                  >
                    {scheduleObjAll[s]
                      .sort((a, b) => a.no - b.no)
                      .map((schObj, key) => {
                        return (
                          <div
                            key={key}
                            //   onClick={() => handleChooseSchBox(schObj)}
                          >
                            <SchBox schObj={schObj} />
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <Typography color="error">
                    No schedule for this light
                  </Typography>
                )}
              </div>
            );
          })}
      </div>
    </BackgroundPaper>
  );
}
