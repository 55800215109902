import moment from "moment";
import {
  IconBulb,
  IconExit,
  IconTube,
  IconTubeOn,
  IconOwl,
  IconPanellight,
  IconPanellightOn,
  IconIndicatorGreen,
  IconIndicatorRed,
  IconRepeater,
  IconGearChecking,
  IconQuestionMark,
  IconMotionDetected,
  IconMotionUnDetected,
  IconMotionUnDetected1,
  IconFire,
  IconFireOff,
  IconFireBreakGlassOn,
  IconFireBreakGlassOff,
  IconFireSmokeOn,
  IconFireSmokeOff,
  IconNurseCallOff,
  IconNurseCallOn,
  IconZoneControl,
  IconZoneControl1,
  IconZoneControl2,
  IconZoneControl3,
  IconZoneControl4,
  IconZoneControl5,
  IconZoneControl6,
  IconZoneControl7,
  IconZoneControl8,
  IconZoneControl9,
  IconZoneControl10,
  IconZoneControl11,
  IconTube70,
  IconTube30,
  IconSensorGeneral,
  IconLock,
  IconUnlock,
} from "../asset/iconPng";
import LightswitchDouble00 from "asset/svg/lightswitch_double_00.svg";
import LightswitchDouble01 from "asset/svg/lightswitch_double_01.svg";
import LightswitchDouble10 from "asset/svg/lightswitch_double_10.svg";
import LightswitchDouble11 from "asset/svg/lightswitch_double_11.svg";
import LightswitchReplicate from "asset/svg/lightswitch_replicate0.svg";
import LightSwitchSingle0 from "asset/svg/lightswitch_single_0.svg";
import LightSwitchSingle1 from "asset/svg/lightswitch_single_1.svg";
import Downlight0 from "asset/svg/downlight0.svg";
import Downlight20 from "asset/svg/downlight20.svg";
import Downlight40 from "asset/svg/downlight40.svg";
import Downlight80 from "asset/svg/downlight80.svg";
import Downlight60 from "asset/svg/downlight60.svg";
import Downlight100 from "asset/svg/downlight100.svg";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export const gatewayColorDark = "#BA4A00";
export const gatewayColorLight = "#F6DDCC";
export const sensorColorDark = "#0E6655";
export const sensorColorLight = "#7DCEA0";
export const lightColorDark = "#1A5276";
export const lightColorLight = "#AED6F1";

export const printPdf = (
  mainTitle,
  subTitle,
  printBody,
  disableMainTitle,
  disableSubTitle
) => {
  var mywindow = window.open("", "Print", "height=600,width=800");
  mywindow.document.write(
    `<html><head><title>${mainTitle}-${subTitle}</title>`
  );
  mywindow.document.write("<style>");
  mywindow.document.write(
    "table {margin:auto;width:100%;boarder: 1px solid grey;border-collapse:collapse}"
  );
  mywindow.document.write(
    "td,th {border: 1px solid grey;border-collapse: collapse;font-size:0.8em}"
  );
  mywindow.document.write(".noPrint {display:none;}");
  mywindow.document.write("</style>");
  mywindow.document.write("</head><body>");
  if (!disableMainTitle) {
    mywindow.document.write(`<h3>${mainTitle}</h3>`);
  }
  if (!disableSubTitle) {
    mywindow.document.write(`<h4>${subTitle}</h4>`);
  }
  mywindow.document.write(`<hr />`);
  mywindow.document.write(printBody);
  mywindow.document.write("</body></html>");
  mywindow.document.close();
  mywindow.focus();
  mywindow.print();
  mywindow.close();
  return true;
};
export const showMsg = (dispatch, title, severity, time) => {
  //severity: success, info, warning, error
  dispatch({
    type: "SHOW_MSG",
    payload: { title, severity, time },
  });
};
export async function asyncForEach(array, callback, onCompleteCallback) {
  if (array?.length > 0) {
    for (let index = 0; index < array?.length; index++) {
      await callback(array[index], index, array);
    }
  }
  if (onCompleteCallback) await onCompleteCallback();
}

export const waitFor = (ms) => new Promise((r) => setTimeout(r, ms));

export function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function arrayEquals(a, b) {
  if (a.length !== b.length) return false;
  const uniqueVal = new Set([...a, ...b]);
  for (const v of uniqueVal) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
}

export const lightStyleNameOf = (style) => {
  const name =
    style === "tube"
      ? "tube fixture"
      : style === "exit"
      ? "exit sign"
      : style === "owl"
      ? "emergency battery light"
      : style === "indicator"
      ? "indication light"
      : style === "downlight"
      ? "down light"
      : style === "panellight"
      ? "panel light"
      : "unknown style light";
  return name;
};
export const lightColorAt = (
  disabledStatus,
  statusA,
  lightLv,
  statusL,
  typeIsDim
) => {
  let lightColor = "#999999";
  if (disabledStatus) {
    lightColor = "#999999";
  } else {
    if (statusL === 1) {
      lightColor = "#FFF599";
    } else {
      if (statusA === 0 && !typeIsDim) {
        lightColor = "#FFE600";
      } else if (statusA === 0 && lightLv > 0 && lightLv <= 25) {
        lightColor = "#FFFDE5";
      } else if (statusA === 0 && lightLv > 25 && lightLv <= 50) {
        lightColor = "#FFF599";
      } else if (statusA === 0 && lightLv > 50 && lightLv <= 75) {
        lightColor = "#FFEE4D";
      } else if (statusA === 0 && lightLv > 75 && lightLv <= 100) {
        lightColor = "#FFE600";
      }
    }
  }

  return lightColor;
};
export const lightIconOfType = (style, status, lightLv, disabledStatus) => {
  let elementIcon = "";
  switch (style) {
    case "tube":
      if (disabledStatus) {
        elementIcon = IconTube;
      } else if (status === 0) {
        if (lightLv <= 30) elementIcon = IconTube30;
        if (lightLv > 30 && lightLv <= 70) elementIcon = IconTube70;
        if (!lightLv || lightLv > 70) elementIcon = IconTubeOn;
      } else {
        //if (status === 1)
        elementIcon = IconTube;
      }
      break;
    case "owl":
      elementIcon = IconOwl;
      break;
    case "exit":
      elementIcon = IconExit;
      break;
    case "downlight":
      if (disabledStatus) {
        elementIcon = Downlight0;
      } else if (status === 0 && lightLv > 0 && lightLv <= 20) {
        elementIcon = Downlight20;
      } else if (status === 0 && lightLv > 20 && lightLv <= 40) {
        elementIcon = Downlight40;
      } else if (status === 0 && lightLv > 40 && lightLv <= 60) {
        elementIcon = Downlight60;
      } else if (status === 0 && lightLv > 60 && lightLv <= 80) {
        elementIcon = Downlight80;
      } else if (status === 0 && lightLv > 80 && lightLv <= 100) {
        elementIcon = Downlight100;
      } else {
        elementIcon = Downlight0;
      }
      break;
    case "panellight":
      if (disabledStatus) {
        elementIcon = IconPanellight;
      } else if (status === 0) {
        elementIcon = IconPanellightOn;
      } else {
        //if (status === 1)
        elementIcon = IconPanellight;
      }
      break;
    case "indicator":
      if (disabledStatus) {
        elementIcon = IconIndicatorGreen;
      } else if (status === 0) {
        elementIcon = IconIndicatorRed;
      } else if (status === 1) {
        elementIcon = IconIndicatorGreen;
      }
      break;
    case "bulb":
      elementIcon = IconBulb;
      break;
    default:
      elementIcon = IconBulb;
  }
  return elementIcon;
};
export const sensorIconOfType = (type, status, status2) => {
  let elementIcon = "";
  switch (type) {
    case "motion": //motion
      elementIcon = status === 0 ? IconMotionUnDetected : IconMotionDetected;
      break;
    case "photo": //photo
      elementIcon = IconMotionUnDetected;
      break;
    case "fire": //fire
      elementIcon = status === 0 ? IconFireOff : IconFire;
      break;
    case "breakglass": //breakglass
      elementIcon = status === 0 ? IconFireBreakGlassOff : IconFireBreakGlassOn;
      break;
    case "smoke": //smoke
      elementIcon = status === 0 ? IconFireSmokeOff : IconFireSmokeOn;
      break;
    case "nursecall": //nursecall
      elementIcon = status === 0 ? IconNurseCallOff : IconNurseCallOn;
      break;
    case "lightswitch":
      //   elementIcon = status === 0 ? IconLightSwitchOff : IconLightSwitchOn;
      elementIcon = status === 0 ? LightSwitchSingle0 : LightSwitchSingle1;
      break;
    case "lightswitchreplicate":
      elementIcon = LightswitchReplicate;
      break;
    case "lightswitchdouble":
      elementIcon =
        !status && !status2
          ? LightswitchDouble00
          : status && !status2
          ? LightswitchDouble10
          : !status && status2
          ? LightswitchDouble01
          : LightswitchDouble11;

      break;
    case "general": //general or unknown
      elementIcon = IconSensorGeneral;
      break;
    default:
      elementIcon = IconQuestionMark;
  }
  return elementIcon;
};

export const sensorStringsOfType = (type, status, status2) => {
  let sensorTypeName, statusTerm;
  switch (type) {
    case "motion":
      sensorTypeName = "Motion sensor";
      statusTerm = status ? "Motion detected" : "No motion detected";
      break;
    case "photo":
      sensorTypeName = "Light responsive sensor";
      statusTerm =
        status === 2 ? "Above limit" : status === 1 ? "Below limit" : "Normal";
      break;
    case "fire":
      sensorTypeName = "General fire alarm";
      statusTerm = status ? "Fire Alarm" : "Normal";
      break;
    case "breakglass":
      sensorTypeName = "Fire breakglass unit";
      statusTerm = status ? "Alarm" : "Normal";
      break;
    case "smoke":
      sensorTypeName = "Smoke detector";
      statusTerm = status ? "Alarm" : "Normal";
      break;
    case "nursecall":
      sensorTypeName = "Nurse call button";
      statusTerm = status ? "Call" : "Normal";
      break;
    case "lightswitch":
      sensorTypeName = "1-gang light switch";
      statusTerm = status ? "Switch On" : "Switch Off";
      break;
    case "lightswitchdouble":
      sensorTypeName = "2-gang light switch";
      if (status && status2) statusTerm = "Switch 1 & 2 On";
      if (status && !status2) statusTerm = "Switch 1 On / Switch 2 Off";
      if (!status && status2) statusTerm = "Switch 1 Off / Switch 2 On";
      if (!status && !status2) statusTerm = "Switch 1 & 2 Off";
      break;
    case "lightswitchreplicate":
      sensorTypeName = "Replicate switch";
      statusTerm = status ? "Switch On" : "Switch Off";
      break;
    default:
      sensorTypeName = "Unknown sensor";
      statusTerm = status ? "Sensor On" : "Sensor Off";
  }
  return { sensorTypeName, statusTerm };
};
export function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getSchBoxColorText(reportType) {
  let backgroundColor, tooltipText;
  switch (reportType) {
    case "check_6month": {
      backgroundColor = "#ffb3d9";
      tooltipText = "6-month test";
      break;
    }
    case "check_12month": {
      backgroundColor = "#ff6600";
      tooltipText = "Annual test";
      break;
    }
    case "check_36month": {
      backgroundColor = "#ff3300";
      tooltipText = "3-year test";
      break;
    }
    default: {
      backgroundColor = "#669999";
      tooltipText = "Monthly test";
    }
  }
  return { backgroundColor, tooltipText };
}

export function getConnectStatusOf(lightObj) {
  const { zigbeeConnected, timeStamp, comm } = lightObj || {};
  let status = "disconnected";
  const latestDataTimeFromNow = moment().diff(moment(timeStamp), "minutes");
  if (latestDataTimeFromNow > 15 && timeStamp) {
    status = "timeout";
  } else if (
    (zigbeeConnected || comm === "wifi" || latestDataTimeFromNow < 10) &&
    timeStamp
  ) {
    status = "connected";
  }
  return status;
}
export function getZoneControlIconArray() {
  return [
    "finger",
    "greenPwr",
    "redPwr",
    "greenPwrSq",
    "redPwrSq",
    "bluePwr",
    "orangePwr",
    "clock",
    "dimColor",
    "dimOrange",
  ];
}
export function zoneControlIconOf(type) {
  let icon;
  switch (type) {
    case "greenPwr": {
      icon = IconZoneControl1;
      break;
    }
    case "redPwr": {
      icon = IconZoneControl2;
      break;
    }
    case "bluePwr": {
      icon = IconZoneControl3;
      break;
    }
    case "orangePwr": {
      icon = IconZoneControl4;
      break;
    }
    case "clock": {
      icon = IconZoneControl5;
      break;
    }
    case "dimColor": {
      icon = IconZoneControl6;
      break;
    }
    case "dimOrange": {
      icon = IconZoneControl7;
      break;
    }
    case "greenPwrSq": {
      icon = IconZoneControl8;
      break;
    }
    case "redPwrSq": {
      icon = IconZoneControl9;
      break;
    }
    case "dimSlider": {
      icon = IconZoneControl10;
      break;
    }
    case "delaySwitch": {
      icon = IconZoneControl11;
      break;
    }
    case "unlock": {
      icon = IconUnlock;
      break;
    }
    default: {
      icon = IconZoneControl;
    }
  }
  return icon;
}

export const getRandomHexID = (noOfByte) => {
  let randomID = "";

  for (let i = 0; i < noOfByte; i++) {
    const b = Math.floor(Math.random() * 254);
    const h = b < 16 ? "0" + b.toString(16) : b.toString(16);
    randomID += h;
  }
  return randomID;
};

export const newArrayBetween = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};

export function isEmptyJson(obj) {
  return Object.keys(obj || {}).length === 0;
}

export function arrayRemoveDuplicateBy(arr, duplicateField) {
  let newArray = [];
  let uniqueObject = {};
  for (let i in arr) {
    const objTitle = arr[i][duplicateField];
    uniqueObject[objTitle] = arr[i];
  }
  for (let i in uniqueObject) {
    newArray.push(uniqueObject[i]);
  }
  return newArray;
}

export function weekDayStringOf(isoWeekDay) {
  const obj = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun",
    100: "All weekday",
  };
  return obj[isoWeekDay];
}
export function weekDayShortStringOf(isoWeekDay) {
  const obj = {
    1: "M",
    2: "T",
    3: "W",
    4: "T",
    5: "F",
    6: "S",
    7: "S",
    100: "All week",
  };
  return obj[isoWeekDay];
}

export function macStringOf(arr) {
  let macString = "";
  arr?.forEach((m, key) => {
    if (m < 16) {
      macString += "0" + m.toString(16);
    } else {
      macString += m.toString(16);
    }

    if (key < 5) macString += ".";
  });
  return macString;
}

export function apStringOf(arr) {
  let apString = "Skymon_";
  arr?.forEach((m, key) => {
    if (m < 16) {
      apString += "0" + m.toString(16);
    } else {
      apString += m.toString(16);
    }
  });
  return apString;
}

export function ipStringOf(arr) {
  let ipString = "";
  arr?.forEach((p, key) => {
    ipString += p;
    if (key < 3) ipString += ".";
  });
  return ipString;
}

export const windowConfirm = (title, onConfirm) => {
  confirmAlert({
    message: title,
    buttons: [
      {
        label: "Yes",
        onClick: onConfirm,
      },
      { label: "No", onClick: null },
    ],
  });
};
