//General use
export const PowerOff = require("./power_off.png").default;
export const PowerOffColor = require("./power_off_color.png").default;
export const Power = require("./power.png").default;
export const PowerColor = require("./power_color.png").default;
export const LightBulbOn = require("./lightBulbOn.png").default;
export const LightBulbOnColor = require("./lightBulbOnColor.png").default;
export const LightBulbOff = require("./lightBulbOff.png").default;
export const LightBulbOffColor = require("./lightBulbOffColor.png").default;
export const PowerCut = require("./powerCut.png").default;
export const Message = require("./message.png").default;
export const InsertDriveFile = require("./insertDriveFile.png").default;
export const Dialpad = require("./dialpad.png").default;
export const Cancel = require("./cancel.png").default;
export const IconControlFinger = require("./icon_control_finger.png").default;
export const IconBatteryCharge = require("./icon_battery_charge.png").default;
export const IconBatteryChargeWhite = require("./icon_battery_charge_white.png")
  .default;
export const IconBatteryDischarge = require("./icon_battery_discharge.png")
  .default;
export const IconBatteryDischargeWhite = require("./icon_battery_discharge_white.png")
  .default;
export const IconLock = require("./icon_lock.png").default;
export const IconUnlock = require("./icon_unlock.png").default;
export const IconDimmable = require("./icon_dimmable.png").default;
export const IconSystemStart = require("./icon_system_start.png").default;
export const IconDataUpload = require("./icon_data_upload.png").default;
export const IconTestStart = require("./icon_test_start.png").default;
export const IconTestFinish = require("./icon_test_finish.png").default;
export const IconReceiveTestRequest = require("./icon_receive_test_request.png")
  .default;
export const IconIntermediateAction = require("./icon_intermediate_action.png")
  .default;

//Map devices
export const IconQuestionMark = require("./icon_questionmark.png").default;
export const IconZoneControl = require("./icon_zone_control.png").default;
export const IconZoneControl1 = require("./icon_zone_control_1.png").default;
export const IconZoneControl2 = require("./icon_zone_control_2.png").default;
export const IconZoneControl3 = require("./icon_zone_control_3.png").default;
export const IconZoneControl4 = require("./icon_zone_control_4.png").default;
export const IconZoneControl5 = require("./icon_zone_control_5.png").default;
export const IconZoneControl6 = require("./icon_zone_control_6.png").default;
export const IconZoneControl7 = require("./icon_zone_control_7.png").default;
export const IconZoneControl8 = require("./icon_zone_control_8.png").default;
export const IconZoneControl9 = require("./icon_zone_control_9.png").default;
export const IconZoneControl10 = require("./icon_zone_control_10.png").default;
export const IconZoneControl11 = require("./icon_zone_control_11.png").default;
export const IconBulb = require("./icon_elight_default.png").default;
export const IconExit = require("./icon_elight_exit.png").default;
export const IconOwl = require("./icon_elight_owl.png").default;
export const IconTube = require("./icon_elight_tube.png").default;
export const IconTube30 = require("./icon_elight_tube_on_30.png").default;
export const IconTube70 = require("./icon_elight_tube_on_70.png").default;
export const IconBacklight = require("./icon_backlight.png").default;
export const IconTubeOn = require("./icon_elight_tube_on.png").default;
export const IconDownlight = require("./icon_downlight.png").default;
export const IconDownligthOn = require("./icon_downlight_on.png").default;
export const IconPanellight = require("./icon_panellight.png").default;
export const IconPanellightOn = require("./icon_panellight_on.png").default;
export const IconIndicatorRed = require("./icon_indicator_red.png").default;
export const IconIndicatorGreen = require("./icon_indicator_green.png").default;
export const IconRepeater = require("./icon_repeater.png").default;
export const IconRepeater2 = require("./icon_repeater2.png").default;
export const Router = require("./router.png").default;
export const IconMotionDetected = require("./icon_motion_detected.png").default;
export const IconMotionUnDetected = require("./icon_motion_undetected.png")
  .default;
export const IconMotionUnDetected1 = require("./icon_motion_undetected1.png")
  .default;
export const IconGateway = require("./icon_gateway.png").default;
export const IconLux = require("./icon_Lux.png").default;
export const IconTempAmb = require("./icon_TempAmb.png").default;
export const IconGearChecking = require("./icon_gearchecking.png").default;
export const IconFire = require("./icon_Fire.png").default;
export const IconFireOff = require("./icon_FireOff.png").default;
export const IconFireBreakGlassOn = require("./icon_fire_breakglass_on.png")
  .default;
export const IconFireBreakGlassOff = require("./icon_fire_breakglass_off.png")
  .default;
export const IconFireSmokeOn = require("./icon_fire_smoke_on.png").default;
export const IconFireSmokeOff = require("./icon_fire_smoke_off.png").default;
export const IconNurseCallOn = require("./icon_nursecall_on.png").default;
export const IconNurseCallOff = require("./icon_nursecall_off.png").default;
export const IconSensorGeneral = require("./icon_sensor_general.png").default;
export const IconLightSwitchOn = require("./icon_light_switch_on.png").default;
export const IconLightSwitchOff = require("./icon_light_switch_off.png")
  .default;
