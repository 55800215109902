import { light } from "@material-ui/core/styles/createPalette";
import { asyncForEach } from "./generalActions";
import moment from "moment";
//For API actions, able to receive "success" or "fail" from the call.

///////INITIAL///////////////INITIAL///////////////INITIAL//////////

export async function getCollectionList(serverIP) {
  console.log("Test by getting CollectionList", serverIP);
  try {
    const response = await fetch(`${serverIP}/api/getCollectionList`, {
      method: "GET",
    });
    // console.log({ response });
    if (response.status === 200) {
      const collObjArr = await response.json();
      //   console.log("data", collObjArr);
      return collObjArr;
    }
  } catch (err) {
    console.log("getCollectionList error", err);
  }
}

export async function loginLS(email, password, dispatch) {
  try {
    const response = await fetch(
      `${global.serverIP}/api/general/login?email=${email}&password=${password}`,
      { method: "GET" }
    );
    console.log({ response });
    if (response.status === 200) {
      const data = await response.json();
      console.log("data", data);
      if (data.loginApproved) {
        global.user = email;
        dispatch({
          type: "LOGIN_APPROVED",
          payload: data,
        });
        return "success";
      } else {
        alert(data.msg);
        return "fail";
      }
    }
  } catch (e) {
    console.log(e);
    return "fail";
  }
}

export async function fetchInitLS(uid, dispatch) {
  //   dispatch({
  //     type: "UPDATED_INIT_LOAD_PROGRESS",
  //     payload: {
  //       progress: 1,
  //       progressText: `Fetching map object data...`,
  //     },
  //   });
  const query = { inUser: { $in: [uid] } };
  const mapObjArray = await getDocsByQuery("map", query);
  const mapObjAll = Object.fromEntries(
    mapObjArray.map((obj) => [obj.mapID, obj])
  );
  dispatch({
    type: "FETCHED_INIT",
    payload: { mapObjAll },
  });
}

export async function fetchLightObjInMap(mapID, dispatch) {
  const queryM = { mapID: { $in: [mapID] } };
  const arr = await getDocsByQuery("serial", queryM);
  const objAll = Object.fromEntries(arr.map((obj) => [obj.serial, obj]));
  dispatch({
    type: "FETCHED_LIGHT_OBJ_ALL",
    payload: objAll,
  });
  const lightArray = arr.map((obj) => obj.serial);
  asyncForEach(lightArray, async (s) => {
    const schObj = await getOneDocByQuery(
      "schedule",
      { serial: s },
      "last",
      "schedule_timeStamp",
      moment().add(6, "months").valueOf(),
      moment().valueOf()
    );
    dispatch({
      type: "UPDATED_SERIAL_NEXT_SCHEDULE_IN_MAP",
      payload: { serial: s, schObj: schObj },
    });
  });

  //   await fetchScheduleObjInMap(lightArray, dispatch);
  //   await fetchReportArrayInMap(lightArray, dispatch);
}
export async function fetchGatewayObjInMap(mapID, dispatch) {
  const queryM = { mapID: { $in: [mapID] } };
  const arr = await getDocsByQuery("gateway", queryM);
  const objAll = Object.fromEntries(arr.map((obj) => [obj.gatewayID, obj]));
  dispatch({
    type: "FETCHED_GATEWAY_OBJ_ALL",
    payload: objAll,
  });
}
export async function fetchRepeaterObjInMap(mapID, dispatch) {
  const queryM = { mapID: { $in: [mapID] } };
  const arr = await getDocsByQuery("repeater", queryM);
  const objAll = Object.fromEntries(arr.map((obj) => [obj.repeaterID, obj]));
  dispatch({
    type: "FETCHED_REPEATER_OBJ_ALL",
    payload: objAll,
  });
}
export async function fetchSubGroupObjInMap(mapID, dispatch) {
  const queryM = { mapID: mapID };
  const arr = await getDocsByQuery("subGroup", queryM);
  const objAll = Object.fromEntries(arr.map((obj) => [obj.subGroupID, obj]));
  dispatch({
    type: "FETCHED_SUBGROUP_OBJ_ALL",
    payload: objAll,
  });
}
export async function fetchSceneObjInMap(mapID, dispatch) {
  const queryM = { mapID: mapID };
  const arr = await getDocsByQuery("scene", queryM);
  const objAll = Object.fromEntries(arr.map((obj) => [obj.sceneID, obj]));
  dispatch({
    type: "FETCHED_SCENE_OBJ_ALL",
    payload: objAll,
  });
}
export async function fetchSensorObjInMap(mapID, dispatch) {
  const arr = await getDocsByQuery("sensor", { mapID: { $in: [mapID] } });
  const objAll = Object.fromEntries(arr.map((obj) => [obj.sensorID, obj]));
  dispatch({
    type: "FETCHED_SENSOR_OBJ_ALL",
    payload: objAll,
  });
}
export async function fetchScheduleObjInMap(lightArray, dispatch) {
  const arr = await getDocsByArray("schedule", "serial", lightArray);
  console.log("schedule array", arr);
  let scheduleObjAll = {};
  arr.forEach((obj) => {
    const serial = obj.serial;
    if (!scheduleObjAll[serial]) {
      scheduleObjAll[serial] = [obj];
    } else {
      scheduleObjAll[serial].push(obj);
    }
  });
  dispatch({
    type: "FETCHED_SCHEDULE_OBJ_ALL",
    payload: scheduleObjAll,
  });
}
export async function fetchReportArrayInMap(lightArray, dispatch) {
  const arr = await getDocsByArray("report", "serial", lightArray);
  dispatch({
    type: "FETCHED_REPORT_ARRAY_IN_MAP",
    payload: arr,
  });
}

///////GET ACTIONS//////////////GET ACTIONS//////////////GET ACTIONS////////

export async function getDocsByQuery(collection, query) {
  try {
    const response = await fetch(
      `${global.serverIP}/api/getDocsByQuery/${collection}`,
      {
        method: "POST",
        body: JSON.stringify(query),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const result = await response.json();
      console.log("fetched:", result);
      if (result.result !== "fail") {
        return result.result;
      } else {
        alert("getDocsByQuery failed");
      }
    }
  } catch (err) {
    alert("getDocsByQuery failed");
  }
}
export async function getOneDocByQuery(
  collection,
  matchQuery,
  order,
  rangeField,
  upperLimit,
  lowerLimit
) {
  //order: "first" or "last"
  order = order ? order : "first";
  try {
    let query = { ...matchQuery };
    if (rangeField && upperLimit > lowerLimit) {
      query[rangeField] = { $lt: upperLimit, $gt: lowerLimit };
    }
    const response = await fetch(
      `${global.serverIP}/api/getOneDocByQuery/${collection}/${order}`,
      {
        method: "POST",
        body: JSON.stringify(query),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const result = await response.json();
      //   console.log("getOneDocByQuery result:", result);
      return result.result;
    }
  } catch (err) {
    return "fail";
  }
}
export async function getDocsBetweenRange(
  collection,
  matchQuery,
  rangeField,
  upperLimit,
  lowerLimit,
  orderByField,
  order
) {
  try {
    let query = matchQuery;
    let combinedQuery;
    if (rangeField) {
      query = { ...query, [rangeField]: { $lt: upperLimit, $gt: lowerLimit } };
    }
    if (orderByField) {
      const orderByVal = order === "asc" ? 1 : -1;
      const orderQuery = { [orderByField]: orderByVal };
      combinedQuery = { $query: query, $orderby: orderQuery };
    } else {
      combinedQuery = { $query: query };
    }

    const response = await fetch(
      `${global.serverIP}/api/getDocsByQuery/${collection}`,
      {
        method: "POST",
        body: JSON.stringify(combinedQuery),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const result = await response.json();
      console.log("getDocsBetweenRange fetched:", result);
      if (result.result !== "fail") {
        return result.result;
      } else {
        alert("getDocsBetweenRange failed");
      }
    }
  } catch (err) {
    alert("getDocsBetweenRange failed");
  }
}

export async function getDocsByArray(collection, byFieldName, searchArray) {
  const query = { [byFieldName]: { $in: searchArray } };
  try {
    const arr = await getDocsByQuery(collection, query);
    return arr;
  } catch (e) {
    return "fail";
  }
}

export async function getDocsByArrayAndMatchFieldLS(
  collection,
  byFieldName,
  searchArray,
  matchFieldArray,
  matchFieldCompareVal
) {
  let query = { [byFieldName]: { $in: searchArray } };
  matchFieldArray.forEach((f, i) => {
    query[f] = matchFieldCompareVal[i];
  });
  try {
    const arr = await getDocsByQuery(collection, query);
    return arr;
  } catch (e) {
    return "fail";
  }
}

/////////SET ACTIONS////////////////SET ACTIONS////////////////SET ACTIONS/////////

export async function updateFieldDB(collection, query, updateObj) {
  console.log("updateFieldDB", collection);
  const body = { query, updateObj };
  try {
    const response = await fetch(
      `${global.serverIP}/api/updateField/${collection}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const res = await response.json();
      console.log("updateFieldDB response", res.result);
      return res.result;
    }
  } catch (err) {
    return "fail";
  }
}

export async function insertDocDB(collection, insertItem) {
  console.log("insertDocDB", collection);
  try {
    const response = await fetch(
      `${global.serverIP}/api/insertDoc/${collection}`,
      {
        method: "POST",
        body: JSON.stringify(insertItem),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const res = await response.json();
      console.log("insertDocDB response", res.result);
      return res.result;
    }
  } catch (err) {
    return "fail";
  }
}
export async function insertManyDocsDB(collection, insertObjArray) {
  //   console.log("insertManyDocsDB", collection);
  let newArray = [];
  insertObjArray.forEach((obj) => {
    //each obj need to remove _id before insert
    if (obj.hasOwnProperty("_id")) {
      delete obj._id;
    }
    newArray = [...newArray, obj];
  });
  //   console.log({ newArray });
  try {
    const response = await fetch(
      `${global.serverIP}/api/insertManyDocs/${collection}`,
      {
        method: "POST",
        body: JSON.stringify(newArray),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const res = await response.json();
      console.log("insertManyDocsDB response", res.result);
      return res.result;
    }
  } catch (err) {
    return "fail";
  }
}
export async function fieldArrayPushDB(
  collection,
  query,
  arrayName,
  pushValue
) {
  console.log("fieldArrayPushDB", collection);
  const body = {
    query,
    arrayName,
    pushValue,
  };
  try {
    const response = await fetch(
      `${global.serverIP}/api/fieldArrayPush/${collection}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const res = await response.json();
      console.log("fieldArrayPushDB response", res.result);
      return res.result;
    }
  } catch (err) {
    return "fail";
  }
}
export async function fieldArrayRemoveDB(
  collection,
  query,
  arrayName,
  removeValue
) {
  console.log("fieldArrayRemoveDB", collection);
  const body = {
    query,
    arrayName,
    removeValue,
  };
  try {
    const response = await fetch(
      `${global.serverIP}/api/fieldArrayRemove/${collection}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const res = await response.json();
      console.log("fieldArrayRemoveDB response", res.result);
      return res.result;
    }
  } catch (err) {
    return "fail";
  }
}
export async function updateRemoveFieldDB(collection, query, removeObj) {
  console.log("updateRemoveFieldDB", collection);
  const body = {
    query,
    removeObj,
  };
  try {
    const response = await fetch(
      `${global.serverIP}/api/updateRemoveField/${collection}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const res = await response.json();
      console.log("updateRemoveFieldDB response", res.result);
      return res.result;
    }
  } catch (err) {
    return "fail";
  }
}
export async function deleteDocByQuery(collection, query) {
  console.log("deleteDocByQuery", collection);
  const body = {
    query,
  };
  try {
    const response = await fetch(
      `${global.serverIP}/api/deleteDocByQuery/${collection}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const res = await response.json();
      console.log("deleteDocByQuery response", res.result);
      return res.result;
    }
  } catch (err) {
    return "fail";
  }
}
export async function deleteManyDocsByQuery(collection, query) {
  console.log("deleteManyDocsByQuery", collection);
  const body = {
    query,
  };
  try {
    const response = await fetch(
      `${global.serverIP}/api/deleteManyDocsByQuery/${collection}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const res = await response.json();
      console.log("deleteManyDocsByQuery response", res.result);
      return res.result;
    }
  } catch (err) {
    return "fail";
  }
}
