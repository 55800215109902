import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Typography, IconButton } from "@material-ui/core";
import moment from "moment";
// import Divider10 from "./Divider10";
import { Edit, Done, Close } from "@material-ui/icons";
import PropTypes from "prop-types";
import ModalSM from "components/ModalSM";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";

export default function DialogInputScheduleTime({
  initialVal,
  handleSave,
  title,
}) {
  const [open, setOpen] = useState(false);
  const [timeStamp, setTimeStamp] = useState(0);
  const [year, setYear] = useState(2020);
  const [month, setMonth] = useState(1);
  const [day, setDay] = useState(1);
  const [dayArray, setDayArray] = useState([]);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const closeDialog = () => setOpen(false);
  useEffect(() => {
    setTimeStamp(initialVal); //timestamp
    const m = moment(initialVal);
    setYear(m.year());
    setMonth(m.month() + 1);
    setDay(m.date());
    setHour(m.hour());
    setMinute(m.minute());
  }, [initialVal]);
  useEffect(() => {
    const arr = [...Array(daysInMonth(month, year) + 1).keys()].filter(
      (n) => n > 0
    );
    setDayArray(arr);
    function daysInMonth(m, y) {
      return new Date(y, m, 0).getDate();
    }
  }, [year, month]);
  const thisYear = moment().year();
  const yearArray = [thisYear, thisYear + 1, thisYear + 2];
  const monthArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const hourArray = [...Array(24).keys()];
  const minuteArray = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

  return (
    <React.Fragment>
      <Edit onClick={() => setOpen(!open)} style={{ cursor: "pointer" }} />
      <ModalSM
        minWidth="40vw"
        open={open}
        onClose={closeDialog}
        onSave={() => {
          const newTsp = moment()
            .year(year)
            .month(month - 1)
            .date(day)
            .hour(hour)
            .minute(minute)
            .second(0);
          handleSave(newTsp);
          closeDialog();
        }}
      >
        <Typography variant="h6" display="block">
          {title}
        </Typography>
        <Typography>Input schedule time:</Typography>
        <Divider10 />
        <DivInline>
          <TextField
            select
            variant="outlined"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            size="medium"
          >
            {yearArray.map((v) => (
              <MenuItem key={v} value={v}>
                <Typography variant="h5" align="right">
                  {v}
                </Typography>
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            variant="outlined"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            size="medium"
          >
            {monthArray.map((v) => (
              <MenuItem key={v} value={v}>
                <Typography variant="h5" align="right">
                  {v}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            variant="outlined"
            value={day}
            onChange={(e) => setDay(e.target.value)}
            size="medium"
          >
            {dayArray.map((v) => (
              <MenuItem key={v} value={v}>
                <Typography variant="h5" align="right">
                  {v}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </DivInline>
        <DivInline style={{ marginTop: 10 }}>
          <TextField
            select
            variant="outlined"
            value={hour}
            onChange={(e) => setHour(e.target.value)}
            size="medium"
          >
            {hourArray.map((v) => (
              <MenuItem key={v} value={v}>
                <Typography variant="h5" align="right">
                  {v}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="h4">:</Typography>
          <TextField
            select
            variant="outlined"
            value={minute}
            onChange={(e) => setMinute(e.target.value)}
            size="medium"
          >
            {minuteArray.map((v) => (
              <MenuItem key={v} value={v}>
                <Typography variant="h5" align="right">
                  {v}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </DivInline>
      </ModalSM>
    </React.Fragment>
  );
}

DialogInputScheduleTime.propTypes = {
  initialVal: PropTypes.number.isRequired,
  handleSave: PropTypes.func.isRequired,
};
